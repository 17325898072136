import { DataLoadingStatus } from "../Condition/Redux/ConditionEntities";
import { BookingWidgetModeKind, BookingTemplateLoadKind } from '../BookingTemplate/BookingTemplateEntities';
/**
 * This 1st level store of Redux contains all states which:
 * 1> Support UI control;
 * 2> Support logic control.
 * 
 * For example, "BookingFormApiRunningStatus" is used in booking form, but it's value won't be used for booking itself.
 * 
 * Regulations:
 * When introduce more states in, please be organised into groups whose names refelct their purpose or belonging.
 */
export interface UILogicControlState {
    /** For booking form related */
    BookingForm: BookingForm;

    /** For addresses related */
    AddressStatus: AddressStatus;

    /** For Auth0 related */
    Credentials: Credentials;

    /** Mobile (compact) or Desktop (normal) layout. */
    LayoutMode: UILayoutMode;

    /** Decide whether to show menu or not, which is only used for mobile device currently, but will be used to control all devices in the future. */
    ShowMenu: boolean;

    /** Whether the loading gif with dark transparent background need to be displayed on top of the page in order to block further user actions while an operation (e.g: an API call) is in progress in the background. */
    ShouldShowLoading: boolean;

    /** This value is incremented to remount the CardSelector's dropdown component in the DOM, effectively causing the dropdown menu to close without any item selection. */
    CardSelectorRemountCounter: number;
}

/** Mobile or Desktop. (Should it be small vs big?) */
export enum UILayoutMode {
    Mobile = "Mobile",
    Desktop = "Desktop",
}

/**
 * This is a type for a group --> states which support the UI & logic for sign-up or log-in
 */
export interface Credentials {
    /**
     * This enumeration type is to decribe off where the Auth0 sign-up or log-in popups are triggered.
     */
    PopupTriggerSource: CredentialPopupTrigger;
    /**
     * This is indicating whether some request is running relating to Auth0 related operations, such as sign-up, log-in, forgot-password and check user existence.
     * Or, some internal APIs, such as check user existence, within the new Auth0 flows.
     */
    ApiRunningStatus: DataLoadingStatus;
}

/**
 * This enumeration type is to decribe off where the Auth0 sign-up or log-in popups are triggered.
 */
export enum CredentialPopupTrigger {
    FromMenu = 'From menu',

    /** From booking form through the Add card flow. i.e. by clicking on 'Add Card' in the payment selector dropdown. */
    FromGuestBooking = 'From guest booking',

    /** From booking form through sign up to pay driver direct flow. i.e. by clicking on the pay driver direct link in the booking form and choosing to signup in the next dialog. */
    FromPayDriverDirectLink = 'From pay driver direct link',
}

/**
 * This is a type for a group --> states which support the UI & logic for booking form
 */
export interface BookingForm {
    /** 
     * This status is tracking, in booking form, if booking or related APIs is running or under other statuses;
     * For example, if "Create booking" Api is progessing or not;
     * This status is faciliting, the displaying or not the loading image attached to booking form.
     */
    ApiRunningStatus: DataLoadingStatus;

    /** If the "strict validation" mode is on or off */
    IsStrictValidationModeOn: boolean;

    /** 
     * AddressStatus.Pickup and Dropoff will decide "Google map" and "landing page" to display;
     * but, landing page should only shows when hard-fresh of the booking form page;
     * this flag to prevent landing pagebto show again after it replaced by Google map, even though no values or focus to pickup and dropoff.
     */
    IsLandingPageFinished: boolean;

    /**
     * Holds the active Booking Tab value
     */
    SelectedBookingTab: BookingTabKind;

    /**
     * Holds the status of the Recent Trip API
     */
    RecentTripLoadingStatus: RecentTripLoadKind;

    /**
     * Determines whether the selected vehicle is serviceable for the specified suburb
     */
    IsSelectedVehicleServiceable: boolean;

    /**
     * Determines add/edit favourite on the booking widget
     */
    BookingWidgetMode: BookingWidgetModeKind;

    /**
     * Holds the status of the Booking Template API
     */
    BookingTemplateLoadingStatus: BookingTemplateLoadKind;

    /**
     * Holds the status of the expanded templates/recents list on the QuickBook tab
     */
    QuickBookExpandedStatus: QuickBookExpandedSection;

    /**
     * True when the the account details could not be populated when copying a booking.
     * 
     * This flag is used when:
     *   1) There is a specified account from booking templates/recents/booking history on account number;
     *   2) There are accounts linked to logged-in users;
     * 
     * If there is no accounts linked to logged in user, the value of this flag is meaningless.
     * Default as false.
     */
    SpecifiedAccountNotFound: boolean;

    /** Whether the header should show or hide */
    ShouldShowHeader: boolean;
    
    /** Is the selected vehicle/service invalid to use with a SATSS voucher. */
    IsInvalidConditionForSatss: boolean;

    /** Whether to show or hide the message about informing the user on how to attain a fare estimate */
    ShouldShowFareEstimateMesssage: boolean;

    /** Whether to show or hide the Snackbar message about informing the user on how to attain a Price Guarantee toggle */
    ShouldShowSnackbarMesssage : boolean;

    /** Passenger mode (simple) vs parcel mode (detailed) */
    ActiveBookingForm: BookingFormKind;

    /** UI editor state for components managing the pickup location */
    Pickup: BookingLocationUiState;

    /** UI editor state for components managing the dropoff location */
    Dropoff: BookingLocationUiState;

    /** Whether any address from a multi-stop booking is being dragged currently. This is used in other components to show/hide some elements. */
    IsDraggingAnAddress: boolean;

    /** Whether the 'Contact name' field should be focused. This is used to manually focus the input field after selectig a vehicle with tab navigation. */
    ShouldContactNameFocused: boolean;
}

export enum QuickBookExpandedSection {
    
    /** Both template and recent trip list are collapsed */
    None = "None",

    /** Template list is expanded */
    Templates = "Templates",

    /** Recent trip list is expanded */
    RecentBookings = "RecentBookings"
}

export enum RecentTripLoadKind {

    /** Default value */
    Idle = 'Idle',

    /** Before calling the GetMyBookings API */
    Loading = 'Loading Recent Trip',    

    /** After calling the GetMyBookings API, recent trip data is returned */
    Loaded = 'Recent Trip Loaded',

    /** After calling the GetMyBookings API, has no previous trips  */
    Empty = 'Has No Recent Trips'
}

export enum RecentTripButtonKind {

    /** When "Book again" button is clicked of a recent trip from the list */
    BookAgain = 'bookagain',
    
    /** When "Book return" button is clicked of a recent trip from the list */
    BookReturn = 'bookreturntrip'
}

export enum BookingTabKind {
    
    /** QuickBook Tab is selected */
    QuickBook = 0,

    /** NewBooking Tab is selected */
    NewBooking = 1,

    /** Active Tab is selected, which is used for mobile mode only */
    ActiveBooking = 2
}

/**
 * This is a type for a group --> states which support the UI & logic for addresses
 * The name of it tries to be distinguishing from BookingEntities.Address
 */
export interface AddressStatus {
    Pickup: AddressStatusType;
    Dropoff: AddressStatusType;
}

/**
 * This type refelcts the status of below properties for pick-up and drop-off addresses.
 *     1> Is focused or not;
 *     2> Does HTML element has any typed value or not;
 */
export interface AddressStatusType {
    IsFocus: boolean;
    DoesInputHaveValue: boolean;
    IsValid: boolean;
}

/** Different booking form types. */
export enum BookingFormKind {
    PassengerBooking = "Passenger Booking",
    ParcelBooking = "Parcel Booking"
}

/** Low level UI state of an input control, e.g. PhoneNumberInput. */
export interface EditorState {

    /** Whether the user is currently editing the value. e.g. text box focused or dropdown list open. */
    IsEditInProgress: boolean;

    /** Whether the input control hasn't been populated at all. This may be a valid state, depending on the UI. */
    IsEmpty: boolean;

    /** Whether the value currently in the UI is valid. */
    IsValid: boolean;

    /** Validation error, populated when IsValid is false. */
    ErrorMessage: string | null;
}

/** Low level UI state for input controls for a location (e.g. pickup) in the booking form. */
export interface BookingLocationUiState {

    /** Editor state for the phone number input */
    ContactNumber: EditorState;

    // I'm anticipating more fields here in future, e.g. contact name.
}