import { ReduxStoreSlice } from "../../Redux/ReduxStoreSlice";
import { InitUILogicControlState } from "./UILogicControlState";
import { DataLoadingStatus } from "../Condition/Redux/ConditionEntities";
import { BookingWidgetModeKind, BookingTemplateLoadKind } from "../BookingTemplate/BookingTemplateEntities";
import { UILogicControlState, RecentTripLoadKind, BookingTabKind, CredentialPopupTrigger, QuickBookExpandedSection, UILayoutMode, BookingFormKind, EditorState } from './UILogicControlEntities';

const slice = new ReduxStoreSlice<UILogicControlState>("UILogicControl", InitUILogicControlState);

/** Dispatcher for actions in the UILogicControl state slice. */
export const UILogicControlDispatch = {

    BookingFormApiStart: slice.EmptyAction("BookingForm.ApiRunningStatus to be in-progress", SetBookingFormApiStatusToInProgress),
    BookingFormApiEnd: slice.EmptyAction("BookingForm.ApiRunningStatus to be idle", SetBookingFormApiStatusToIdle),
    OnIsStrictValidationModeOnBookingFormChange: slice.Action("Set BookingForm.IsStrictValidationModeOn", SetStrictValidationMode),
    FinishLandingPage: slice.EmptyAction("BookingForm.IsLandingPageFinished to be true", SetFinishLandingPage),
    OnIsPickupFocusChange: slice.Action("AddressStatus.Pickup.IsFocus", SetPickupFocus),
    OnDoesPickupInputHaveValueChange: slice.Action("AddressStatus.Pickup.DoesInputHaveValue", SetPickupInputHaveValue),
    OnIsDropoffFocusChange: slice.Action("AddressStatus.Dropoff.IsFocus", SetDropoffFocus),
    OnDoesDropoffInputHaveValueChange: slice.Action("AddressStatus.Dropoff.DoesInputHaveValue", SetDropoffInputHaveValue),
    OnRecentBookingLoadingStatusChange: slice.Action("BookingForm.RecentTripLoadingStatus", SetRecentBookingLoadingStatus),
    OnBookingTabSelectionChange: slice.Action("BookingForm.SelectedBookingTab", SetBookingTab),
    ValidateVehicleOnPickupChange: slice.Action("BookingForm.IsSelectedVehicleServiceable", SetValidateVehicle),
    ChangeCredentialsPopupTriggerSource: slice.Action("Credentials.PopupTriggerSource", SetCredentialsPopupTrigger),
    StartCredentialsApiRequest: slice.EmptyAction("Credentials.ApiRunningStatus to be running", SetCredentialsApiStatusToInProgress),
    EndCredentialsApiRequest: slice.EmptyAction("Credentials.ApiRunningStatus to be idle", SetCredentialsApiStatusToIdle),
    OnBookingWidgetModeChange: slice.Action("BookingForm.BookingWidgetMode", SetBookingWidgetMode),
    OnBookingTemplateLoadingStatusChange: slice.Action("BookingForm.BookingTemplateLoadingStatus", SetBookingTemplateLoadingStatus),
    SetPickupValidity: slice.Action("AddressStatus: Set Pickup Validity", ChangePickupValidity),
    SetDropoffValidity: slice.Action("AddressStatus: Set Dropoff Validity", ChangeDropoffValidity),
    ToggleQuickBookSection: slice.Action("BookingForm.QuickBookExpandedStatus", SetQuickBookExpandedStatus),
    SpecifiedAccountNotFound: slice.Action("Change BookingForm.SpecifiedAccountNotFound", SpecifiedAccountNotFound),
    ShowFareEstimateMessage: slice.EmptyAction("Show Fare Estimate Message", ShowFareEstimateMessage),
    HideFareEstimateMessage: slice.EmptyAction("Hide Fare Estimate Message", HideFareEstimateMessage),
    ShowSnackbarForPriceGuaranteeTip: slice.EmptyAction("Show Snackbar Message", ShowSnackbarForPriceGuaranteeTip),
    HideSnackbarForPriceGuaranteeTip: slice.EmptyAction("Hide Snackbar Message", HideSnackbarForPriceGuaranteeTip),    

    /** Change the UI layout mode between mobile and desktop. */
    LayoutMode: slice.Action("Layout Mode", LayoutMode),
    ShowMobileHeader: slice.EmptyAction("Show mobile header", ShowMobileHeader),
    HideMobileHeader: slice.EmptyAction("Hide mobile header", HideMobileHeader),
    OpenMenu: slice.EmptyAction("Open the menu", OpenMenu),
    CloseMenu: slice.EmptyAction("Close the menu", CloseMenu),
    InvalidateServiceForSatss: slice.EmptyAction("Invalid Service For SATSS", MakeServiceInvalidForSatss),
    ClearSatssError: slice.EmptyAction("Clear SATSS Error", ClearSatssError),
    ActiveBookingForm: slice.Action("Active Booking Form", ChangeBookingFormType),

    /** Set the UI editor state for the pickup location phone number input. */
    PickupPhoneNumber: slice.Action("Pickup Phone Number", PickupPhone),

    /** Set the UI editor state for the dropoff location phone number input. */
    DropoffPhoneNumber: slice.Action("Dropoff Phone Number", DropoffPhone),

    /** Show loading gif with transparent dark background. Used to prevent user actions and let the user know a background process is running while an operation is in progress. */
    ShowLoading: slice.EmptyAction("Show Loading", ShowLoading),

    /** Hide loading gif and the background after finishes an operation (success or failure state) */
    HideLoading: slice.EmptyAction("Hide Loading", HideLoading),

    /** 
     * Manually close the card selector dropdown entry. 
     * This is achieved by causing the Select component to be unmounted and remounted in the DOM, returning it to a default state.
     * Only intended for use by virtual non-data menu items in the list. 
     */
    CloseCardSelectorDropdown: slice.EmptyAction("Close Card Selector Dropdown", CloseCardSelectorDropdown),

    /** Set if any address input field is being dragged currently. It is used by other components to enable some UI styling. */
    IsDraggingAnAddress: slice.Action("Is Dragging An Address", SetAddressDraggingStatus),

    /** Set whether the 'Contact name' field should be focused. This is used to manually focus the input field after selectig a vehicle with tab navigation. */
    ShouldContactNameFocused: slice.Action("Is Contact Name Focused", SetContactNameFocused),
};

/** Reducer for the UILogicControl store slice */
export const UILogicControlReducer = slice.MakeCombinedReducer();

function SetBookingFormApiStatusToInProgress(state: UILogicControlState) : UILogicControlState {
    return {
        ...state,
        BookingForm: {
            ...state.BookingForm,
            ApiRunningStatus: DataLoadingStatus.InProgress
        }
    }
}

function SetBookingFormApiStatusToIdle(state: UILogicControlState) : UILogicControlState {
    return {
        ...state,
        BookingForm: {
            ...state.BookingForm,
            ApiRunningStatus: DataLoadingStatus.Idle
        }
    }
}

function SetStrictValidationMode(state: UILogicControlState, payload: boolean) : UILogicControlState {
    return {
        ...state,
        BookingForm: {
            ...state.BookingForm,
            IsStrictValidationModeOn: payload
        }
    }
}

function SetFinishLandingPage(state: UILogicControlState) : UILogicControlState {
    return {
        ...state,
        BookingForm: {
            ...state.BookingForm,
            IsLandingPageFinished: true
        }
    }
}

function SetPickupFocus(state: UILogicControlState, payload: boolean) : UILogicControlState {
    return {
        ...state,
        AddressStatus: {
            ...state.AddressStatus,
            Pickup: {
                ...state.AddressStatus.Pickup,
                IsFocus: payload
            }
        }
    }
}

function SetPickupInputHaveValue(state: UILogicControlState, payload: boolean) : UILogicControlState {
    return {
        ...state,
        AddressStatus: {
            ...state.AddressStatus,
            Pickup: {
                ...state.AddressStatus.Pickup,
                DoesInputHaveValue: payload
            }
        }
    }
}

function SetDropoffFocus(state: UILogicControlState, payload: boolean) : UILogicControlState {
    return {
        ...state,
        AddressStatus: {
            ...state.AddressStatus,
            Dropoff: {
                ...state.AddressStatus.Dropoff,
                IsFocus: payload
            }
        }
    }
}

function SetDropoffInputHaveValue(state: UILogicControlState, payload: boolean) : UILogicControlState {
    return {
        ...state,
        AddressStatus: {
            ...state.AddressStatus,
            Dropoff: {
                ...state.AddressStatus.Dropoff,
                DoesInputHaveValue: payload
            }
        }
    }
}

function SetRecentBookingLoadingStatus(state: UILogicControlState, payload: RecentTripLoadKind) : UILogicControlState {
    return {
        ...state, 
        BookingForm : {
            ...state.BookingForm, 
            RecentTripLoadingStatus : payload
        }
    }
}

function SetBookingTab(state: UILogicControlState, payload: BookingTabKind) : UILogicControlState {
    return {
        ...state, 
        BookingForm : {
            ...state.BookingForm, 
            SelectedBookingTab : payload
        }
    }
}

function SetValidateVehicle(state: UILogicControlState, payload: boolean) : UILogicControlState {
    return {
        ...state, 
        BookingForm : {
            ...state.BookingForm, 
            IsSelectedVehicleServiceable : payload
        }
    }
}

function SetCredentialsPopupTrigger(state: UILogicControlState, payload: CredentialPopupTrigger) : UILogicControlState {
    return {
        ...state, 
        Credentials : {
            ...state.Credentials, 
            PopupTriggerSource : payload
        }
    }
}

function SetCredentialsApiStatusToInProgress(state: UILogicControlState) : UILogicControlState {
    return {
        ...state, 
        Credentials : {
            ...state.Credentials, 
            ApiRunningStatus: DataLoadingStatus.InProgress
        }
    }
}

function SetCredentialsApiStatusToIdle(state: UILogicControlState) : UILogicControlState {
    return {
        ...state, 
        Credentials : {
            ...state.Credentials, 
            ApiRunningStatus: DataLoadingStatus.Idle
        }
    }
}

function SetBookingWidgetMode(state: UILogicControlState, payload: BookingWidgetModeKind) : UILogicControlState {
    return {
        ...state, 
        BookingForm : {
            ...state.BookingForm, 
            BookingWidgetMode : payload
        }
    }
}

function SetBookingTemplateLoadingStatus(state: UILogicControlState, payload: BookingTemplateLoadKind) : UILogicControlState {
    return {
        ...state, 
        BookingForm : {
            ...state.BookingForm, 
            BookingTemplateLoadingStatus : payload
        }
    }
}

/**
 * Set the validity of Pickup address when loading the address from history or favourite.
 */
function ChangePickupValidity(state: UILogicControlState, payload: boolean): UILogicControlState {
    return {
        ...state,
        AddressStatus: {
            ...state.AddressStatus,
            Pickup: {
                ...state.AddressStatus.Pickup,
                IsValid: payload
            }
        }
    }
}
function SpecifiedAccountNotFound(state: UILogicControlState, payload: boolean) : UILogicControlState {
    return {
        ...state, 
        BookingForm : {
            ...state.BookingForm, 
            SpecifiedAccountNotFound: payload
        }
    }
}

/**
 * Set the validity of Dropoff address when loading the address from history or favourite.
 */
function ChangeDropoffValidity(state: UILogicControlState, payload: boolean): UILogicControlState {
    return {
        ...state,
        AddressStatus: {
            ...state.AddressStatus,
            Dropoff: {
                ...state.AddressStatus.Dropoff,
                IsValid: payload
            }
        }
    }
}

function SetQuickBookExpandedStatus(state: UILogicControlState, payload: QuickBookExpandedSection) : UILogicControlState {
    return {
        ...state, 
        BookingForm : {
            ...state.BookingForm, 
            QuickBookExpandedStatus : payload
        }
    }
}

/** Change the UI layout mode between mobile and desktop. */
function LayoutMode(state: UILogicControlState, mode: UILayoutMode): UILogicControlState {
    return {
        ...state,
        LayoutMode: mode,
    };
}

function ShowMobileHeader(state: UILogicControlState) : UILogicControlState {
    return {
        ...state,
        BookingForm: {
            ...state.BookingForm,
            ShouldShowHeader: true
        }
    }
}

function HideMobileHeader(state: UILogicControlState) : UILogicControlState {
    return {
        ...state,
        BookingForm: {
            ...state.BookingForm,
            ShouldShowHeader: false
        }
    }
}

function OpenMenu(state: UILogicControlState) : UILogicControlState {
    return {
        ...state,
        ShowMenu: true
    }
}

function CloseMenu(state: UILogicControlState) : UILogicControlState {
    return {
        ...state,
        ShowMenu: false
    }
}

/** Make the selected condition invalid to use SATSS vouchers. */
function MakeServiceInvalidForSatss(state: UILogicControlState): UILogicControlState {
    return {
        ...state,
        BookingForm: {
            ...state.BookingForm,
            IsInvalidConditionForSatss: true
        }
    }
}

/** Remove the 'invalid to use SATSS voucher' status of the service. i.e.: Not using SATSS or using the correct service type for SATSS. */
function ClearSatssError(state: UILogicControlState): UILogicControlState {
    return {
        ...state,
        BookingForm: {
            ...state.BookingForm,
            IsInvalidConditionForSatss: false
        }
    }
}

function ShowFareEstimateMessage(state: UILogicControlState) : UILogicControlState {
    return {
        ...state,
        BookingForm: {
            ...state.BookingForm,
            ShouldShowFareEstimateMesssage: true
        }
    }
}

function HideFareEstimateMessage(state: UILogicControlState) : UILogicControlState {
    return {
        ...state,
        BookingForm: {
            ...state.BookingForm,
            ShouldShowFareEstimateMesssage: false
        }
    }
}

function ShowSnackbarForPriceGuaranteeTip(state: UILogicControlState) : UILogicControlState {
    return {
        ...state,
        BookingForm: {
            ...state.BookingForm,
            ShouldShowSnackbarMesssage: true
        }
    }
}

function HideSnackbarForPriceGuaranteeTip(state: UILogicControlState) : UILogicControlState {
    return {
        ...state,
        BookingForm: {
            ...state.BookingForm,
            ShouldShowSnackbarMesssage: false
        }
    }
}

/** Change the active booking type (taxi, parcel etc) */
function ChangeBookingFormType(state: UILogicControlState, bookingType: BookingFormKind): UILogicControlState {
    return {
        ...state,
        BookingForm: {
            ...state.BookingForm,
            ActiveBookingForm: bookingType
        }
    }
}

/** Set the UI editor state for the pickup location phone number input. */
function PickupPhone(state: UILogicControlState, editorState: EditorState): UILogicControlState {
    return {
        ...state,
        BookingForm: {
            ...state.BookingForm,
            Pickup: {
                ...state.BookingForm.Pickup,
                ContactNumber: editorState,
            }
        }
    };
}

/** Set the UI editor state for the dropoff location phone number input. */
function DropoffPhone(state: UILogicControlState, editorState: EditorState): UILogicControlState {
    return {
        ...state,
        BookingForm: {
            ...state.BookingForm,
            Dropoff: {
                ...state.BookingForm.Dropoff,
                ContactNumber: editorState,
            }
        }
    };
}

function ShowLoading(state: UILogicControlState): UILogicControlState {
    return {
        ...state,
        ShouldShowLoading: true
    }
}

function HideLoading(state: UILogicControlState): UILogicControlState {
    return {
        ...state,
        ShouldShowLoading: false
    }
}

/** 
     * Manually close the card selector dropdown entry. 
     * This is achieved by causing the Select component to be unmounted and remounted in the DOM, returning it to a default state.
     * Only intended for use by virtual non-data menu items in the list. 
     */
function CloseCardSelectorDropdown(state: UILogicControlState): UILogicControlState {
    return {
        ...state,
        CardSelectorRemountCounter: state.CardSelectorRemountCounter + 1
    };
}

function SetAddressDraggingStatus(state: UILogicControlState, isDragging: boolean): UILogicControlState {
    return {
        ...state,
        BookingForm: {
            ...state.BookingForm,
            IsDraggingAnAddress: isDragging
        }        
    };
}

function SetContactNameFocused(state: UILogicControlState, isFocused: boolean): UILogicControlState {
    return {
        ...state,
        BookingForm: {
            ...state.BookingForm,
            ShouldContactNameFocused: isFocused
        }
    };
}