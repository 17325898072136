import React, { useEffect, useRef } from "react";
import { Dispatch } from "../../Dispatch";
import { Button } from "@mui/material";

/** Dialog that informs the user to allocate additional seats for check-in luggage when selecting the number of seats in order to avoid capacity issues due to getting a smaller vehicle that can't fit all the luggage. Only applicable to MAXI TAXI. */
export const MaxiTaxiSeatGuide: React.FC = () => {

    const buttonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        buttonRef.current!.focus();
    }, []);

    return (
        <div className="error-message-base-panel small-non-error-info-dialog" >
            <div className="emb-problem-panel">Avoid insufficient capacity</div>
            <div className="emb-solution-panel error-text">Add an additional seat for each person travelling with check-in luggage.</div>
            <div className="emb-btn-panel dismiss-button">
                <Button variant="contained" color="primary" onClick={DismissDialog} tabIndex={0} ref={buttonRef}>OK</Button>
            </div>
        </div>
    )
}

function DismissDialog() {
    Dispatch.Dialog.CloseTopDialog();
}