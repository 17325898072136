import { Divider, List, ListItem } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../appState";
import TransparentBlackBg from "../../widgets/transparent-black-background/transparent-black-background";
import { DialogHeaderBackButton } from "../Dialog/CommonDialogHeader";
import { DialogConfigLookup } from "../Dialog/DialogConfig";
import { DialogKind } from "../Dialog/DialogEntities";
import { Dispatch } from "../Dispatch";
import { CnpPaymentKind } from "../Payment/PaymentEntities";
import { UILayoutMode } from "../UILogicControl/UILogicControlEntities";
import { ContentURL, getContentUrl } from "../Utils/ContentURL";
import { PayPal } from "../PayPal/PayPal";
import "./AddPaymentMethod.scss";
import { PaymentCard } from "../../Services/PaymentEntities";
import { useAppState } from "../../Redux/ReduxHooks";
import { SdkLoadingState } from "../PayPal/PayPalState";

const AddPaymentMethodCore: React.FC<PropsFromStore> = (props) => {

    const paypalSdkLoadStatus = useAppState(i => i.PayPal.LoadingStatus);

    /**
     * Open different add payment screens based on the selected payment method to be added.
     */
    async function RedirectToAddPayment(paymentType: CnpPaymentKind) {
        Dispatch.Payment.HideAddPaymentMethodDialog();

        if (paymentType === CnpPaymentKind.CreditOrDebitCard) {
            Dispatch.Payment.ToggleCardRegistrationPanel(true);
        }
        else if (paymentType === CnpPaymentKind.PayPal) {
            await PayPal.RunAddPayPalUiFlow();
        }
    }

    function HideAddPaymentDialog() {
        Dispatch.Payment.HideAddPaymentMethodDialog();
    }

    const listPadding = props.IsMobileDevice ? "0px" : "30px 40px";

    // don't show PayPal option if there is already a PayPal account linked.
    const existingPaypal = props.ExistingPaymentOptions.find(c => c.CardType === CnpPaymentKind.PayPal);

    let paymentOptions = AddPaymentOptions;

    if (!!existingPaypal) {
        paymentOptions = AddPaymentOptions.filter(c => c.PaymentKind !== CnpPaymentKind.PayPal);
    }

    /** Generate the display text for the payment method to be added. Usually, SDK loading is faster (part of the loading happens on user login) and user sees the text '(Loading..)' only for a fraction of second. */
    function GenerateDisplayText(paymentOption: AddPaymentOption): string {

        if (paymentOption.PaymentKind === CnpPaymentKind.PayPal) {

            const isNotFullyLoaded = paypalSdkLoadStatus !== SdkLoadingState.FinishedSuccess;

            let labelText: string = "PayPal";
            if (isNotFullyLoaded) labelText += " (Loading...)";

            return labelText;
        }
        
        return paymentOption.Label;
    }    

    return (
        <>
            <div className="cardRegistrationPanel cardRegistrationPanelDefaultLocation">
                {
                    props.IsMobileDevice && <DialogHeaderBackButton Config={DialogConfigLookup(DialogKind.AddPaymentMethod)!} />
                }
                {
                    !props.IsMobileDevice && <img onClick={HideAddPaymentDialog} className="cardRegistrationPanelClose" src={getContentUrl(ContentURL.images.buttons.greyX)} alt="Close icon" />
                }
                <div className="add-payment-method-title">Add Payment Method</div>
                <List sx={{ width: '100%', padding: listPadding, boxSizing: 'border-box' }}>
                    {paymentOptions.map((option, index) => {
                        return (
                            <>
                                <ListItem onClick={() => RedirectToAddPayment(option.PaymentKind)} key={option.PaymentKind} className="add-payment-method-list-item">
                                    <div className="payment-method-name-section">
                                        <img src={getContentUrl(ContentURL.images.CnpPaymentMethod[option.ImageName])} width="66px" height="40px" alt={option.Label + " icon"} />
                                        <span>{GenerateDisplayText(option)}</span>
                                    </div>                                   
                                    <img src={getContentUrl(ContentURL.images.arrows.arrowRightBlack)} alt="Right arrow" />
                                </ListItem>
                                <Divider component="div" />
                            </>

                        )
                    })
                    }
                </List>
            </div>
            <TransparentBlackBg onClick={() => { }} />
        </>
        
    );
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        IsMobileDevice: state.uiLogicControl.LayoutMode === UILayoutMode.Mobile,
        ExistingPaymentOptions: state.payment.AllCards
    }
}

export const AddPaymentMethod = connect(mapStateToProps)(AddPaymentMethodCore);

/** Data required to make the UI for a specific payment method. */
interface AddPaymentOption {

    /** What type of payment method to be added. e.g: Credit card, PayPal etc. */
    PaymentKind: CnpPaymentKind;

    /** Label in the UI */
    Label: string;

    /** The unique name given to the image in the storage.  */
    ImageName: string;
}

/** List of payment methods the user can add. */
const AddPaymentOptions: AddPaymentOption[] = [
    {
        PaymentKind: CnpPaymentKind.PayPal,
        Label: "PayPal",
        ImageName: "PayPal"
    },
    {
        PaymentKind: CnpPaymentKind.CreditOrDebitCard,
        Label: "Credit/Debit/Cabcharge Card",
        ImageName: "Credit"
    }
    /* This one is hidden for now until this capability is implemented in the card registration service. */
    /*{
        PaymentKind: CnpPaymentKind.Cabcharge,
        Label: "Cabcharge FastCard",
        ImageName: "Cabcharge"
    }*/
];

interface PropsFromStore {
    IsMobileDevice: boolean;
    ExistingPaymentOptions: PaymentCard[]
}

