import React, { Component } from 'react';
import './maxi-seater-selector.scss';
import { getContentUrl, ContentURL }  from '../../modules/Utils/ContentURL';
import { IsMobileDevice } from '../../utils/DetectDevice';
import { MaxiTaxiLookUpType, MaxiTaxiSeaterInfoPayload } from '../../modules/Condition/Redux/ConditionEntities';

interface MaxiSeaterSelectorProps {
    ClickedSeaterNo: number;
    MaxiTaxiSeaterInfo: MaxiTaxiSeaterInfoPayload;
    MaxiTaxiLookUp: MaxiTaxiLookUpType;
    reportMaxiTaxiSeaterNo: (paxCount: number) => void;
}

class MaxiSeaterSelector extends Component<MaxiSeaterSelectorProps> {
    constructor(props: MaxiSeaterSelectorProps) {
        super(props)
        this.changeSeater = this.changeSeater.bind(this);
    }
    
    /**
     * Find the next maxi taxi in order by passenger count, either up (delta == 1) or down (delta == -1). 
     * Skips over gaps.
     */
    changeSeater(delta: number) {
        var temporaryclickedSeaterNo = this.props.ClickedSeaterNo;

        while(true) {
            temporaryclickedSeaterNo += delta;
            if(this.props.MaxiTaxiSeaterInfo.Minimum <= temporaryclickedSeaterNo && temporaryclickedSeaterNo <= this.props.MaxiTaxiSeaterInfo.Maximum) {
                if(this.props.MaxiTaxiLookUp.hasOwnProperty(temporaryclickedSeaterNo)) {
                    this.props.reportMaxiTaxiSeaterNo(temporaryclickedSeaterNo);
                    break;
                } 
            }
            else { break; }
        }
    }

    HandleOnKeyDown(delta: number, event: React.KeyboardEvent) {
        if (event.key === "Enter") {
            this.changeSeater(delta);
        }
    }

    render() {
        const maxiSelectorPanelClass = !IsMobileDevice() ? "booking-carousel-maxi-selector-panel" : "booking-carousel-maxi-selector-panel mobile-maxi-selector-panel"

        return (
            <div className={maxiSelectorPanelClass}>
                <span>Enter number of seats</span>
                <div className="booking-maxi-selector-container">
                    <div className="booking-maxi-selector-box">
                        <img
                            onClick={() => this.changeSeater(-1)}
                            className="position-absolute-left"
                            src={getContentUrl(ContentURL.images.buttons.PickerMinus)}
                            alt="PickerMinus"
                            tabIndex={0}
                            onKeyDown={(e) => this.HandleOnKeyDown(-1, e)} />
                        <span>{this.props.ClickedSeaterNo}</span>
                        <img
                            onClick={() => this.changeSeater(1)}
                            className="position-absolute-right"
                            src={getContentUrl(ContentURL.images.buttons.PickerPlus)}
                            alt="PickerPlus"
                            tabIndex={0}
                            onKeyDown={(e) => this.HandleOnKeyDown(1, e)} />
                    </div>
                    {!IsMobileDevice() && <span>seats</span>}
                </div>  
            </div>
        );
    }
}

export default MaxiSeaterSelector;