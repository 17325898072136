import React from 'react';
import { connect } from "react-redux";
import "./CancelBooking.scss";
import { DialogKind } from '../Dialog/DialogEntities';
import { ContentURL, getContentUrl } from '../Utils/ContentURL';
import '../Verification/ContactVerification.scss';
import { BookingAuthority, BookingDataOwnership } from "../../Services/BookingEntities";
import { ApplicationState } from '../../appState';
import { MapView } from "../../widgets/NavBar/TabEntities";
import { Dispatch } from '../Dispatch';
import { GetBrandedUrl, BrandedImage } from '../Utils/BrandedContentUrls';
import { Api } from '../../Services/Api';
import { RemoveBookingCard } from '../MyBookings/Logic/OperateOnBookingCard';
import { LogEvent } from '../../utils/LogEvent';
import { FeatureFlags } from '../../Config/FeatureFlags';
import { BookingInfo } from '../MyBookings/MyBookingEntities';
import { LogEventInGA4 } from '../../utils/LogEventsInGoogleAnalytics';

interface PropsFromStore {    
    bookingToCancel: BookingInfo;
    BookingsList: BookingInfo[];
    UserId: string;
}

interface stateProps {
    showSpinner: boolean;
}

class CancelBooking extends React.Component<PropsFromStore, stateProps> {

    constructor(props: PropsFromStore) {
        super(props);

        this.closeDialog = this.closeDialog.bind(this);
        this.cancelBooking = this.cancelBooking.bind(this);

        this.state = {
            showSpinner: false
        }
    }

    closeDialog() {
        Dispatch.Dialog.CloseDialog(DialogKind.CancelBooking);
    }

    /**This method helps to make a Cancel Request to Booking Mgmt and on successfull,
     *  it find the cancelled card(By Hashcode) and removes from the Card list and push into Redux store   */
    async cancelBooking() {
        LogEvent.PerformedBookingCancellation();

        this.setState({showSpinner: true});

        const { bookingToCancel } = this.props;

        /**
         * This condition only caters for a very extremely rare situation that 
         * 2 applications opens this cancel dialogs and it got cancel at one,
         * and Redux got cleared by refresh timer.
         */
        if (bookingToCancel && !!bookingToCancel.AccessCode) {

            const bookingKey: BookingAuthority = {
                bookingId: bookingToCancel.BookingID,
                hashCode: bookingToCancel.AccessCode,
            };

            let result;

            if (FeatureFlags.BookingApiV2) {
                result = await Api.BookingV2.CancelBooking(bookingKey);
            } else {
                result = await Api.Booking.CancelByKey(bookingKey);
            } 

            if (result.isSuccess) {
                // Log a GA4 event when booking is cancelled
                const userId = this.props.UserId;
                LogEventInGA4.CancelBooking(userId, bookingToCancel.BookingID);
                // remove the booking from the list
                RemoveBookingCard(this.props.bookingToCancel);

                // If there are no more bookings in the list, change the UI to Map view.
                if (this.props.BookingsList.length === 0) {
                    Dispatch.Tab.SelectItem(MapView);// To Make a forceful view change to MapView when no booking cards available
                }
            }
        }

        this.setState({showSpinner: false});
        this.closeDialog();   // Just close the window on failure as well. Notification to user? Its something business analysis will come back on the behaviour. 
    }

    render() {

        const illustratorClass = this.state.showSpinner ? "popup-illustrator low-opacity" : "popup-illustrator";

        return (
            <div className="contact-details booking-cancel-dialog">
                <h1 className="orange-header">Are you sure?</h1>

                <img src={GetBrandedUrl(BrandedImage.ThinkingGirl)} className={illustratorClass} alt="Image of a girl"></img>
  
                <p className="popup-description">We'd love to get you there. Are you sure you want to cancel this booking?</p>

                <div className="popup-btn-group">
                    <button type="button" className="cancel-btn half-btn" onClick={this.cancelBooking}>Yes Cancel</button>
                    <button type="button" className="active-btn half-btn add-btn-shadow" onClick={this.closeDialog}>No</button>
                </div>

                {this.state.showSpinner ? <div className="cancel-spinner"><img alt="Cancelling" src={getContentUrl(ContentURL.images.Loading)} width="100" /></div> : ""}
            </div>
        );
    }

}

function mapStateToProps(state: ApplicationState): PropsFromStore  {
    return {       
        bookingToCancel: state.myBookings.CancelBookingCandidate!,
        BookingsList: state.myBookings.All,
        UserId: state.authentication?.UserProfile?.UserId ?? BookingDataOwnership.Guest
    };
}

export default connect(mapStateToProps)(CancelBooking);