import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../appState";
import { Dispatch } from "../Dispatch";
import { ContentURL, getContentUrl } from "../Utils/ContentURL";
import './CardSelector.scss';
import { LoginStatusKind } from "../Authentication/AuthEntities";
import CredentialsController from "../Authentication/Login/CredentialsController";
import { LogEvent } from "../../utils/LogEvent";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { CnpPaymentKind, PayDriverOption, PaymentCardErrorType, PaymentOption, PaymentOptionKind } from "../Payment/PaymentEntities";
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput, SelectChangeEvent, InputAdornment, Icon } from "@mui/material";
import { ValidateServicesForSatss } from "../Condition/ConditionValidation";
import { SnackBar } from "../../widgets/InformationDisplay/Snackbar";
import { PopulatePaymentOptions, ShouldPromptUserToAddCard } from "../Payment/PaymentHandler";
import { BookingWorkFlowState } from "../Booking/Redux/BookingState";
import { CheckForCardExpiry } from "../Payment/PaymentCardValidation";
import { CredentialPopupTrigger } from "../UILogicControl/UILogicControlEntities";
import ErrorIcon from '@mui/icons-material/Error';
import { PayPal } from "../PayPal/PayPal";
import { PayPalTokenizePayload } from "braintree-web";
import { AddPayPalDropDownEntry } from "../PayPal/AddPayPalDropDownEntry";
import { AddApplePayDropDownEntry } from "../ApplePay/AddApplePayDropdownEntry";
import { OptionalUI } from "../Booking/OptionalParts/OptionalUI";
import { AddGooglePayDropDownEntry } from "../GooglePay/AddGooglePayDropdownEntry";
import { MyStorage } from "../../Storage";
import { SnackbarType } from "../../widgets/InformationDisplay/SnackbarEntities";
import { InlineCreditCardDropdownEntry } from "../CreditCard/InlineCreditCardDropdownEntry";

interface CardSelectorProps {
    LoginStatus: LoginStatusKind;
    BookingPayload: BookingWorkFlowState;
    ErrorMessage: PaymentCardErrorType | null;
    PaymentOptions: PaymentOption[];
    IsPriceGuaranteeSelected: boolean;
    ShouldPromptUserToAddCard: boolean;

    /** Tokenised representation of a PayPal payment method. Populate only for guest users. */
    PayPalTokenised: PayPalTokenizePayload | null;

    /** Supports remote re-mounting of the dropdown list in the DOM, which effectively forces it closed. This is used by virtual non-data menu items like "Add New Card". */
    RemountCounter: number;

    /** True when CNP options are disallowed due to the metered payment mode being selected. */
    IsCnpDisallowedByMetered: boolean;

    /** False when the CNP payment system is not available. e.g.: MPS or braintree outage. */
    IsPaymentSystemAvailable: boolean;

    /** Whether the Fixed Price toggle is displayed. It is hidden in some specific cases and based on that display status, some changes are applied to the card selector. */
    IsFixedPriceToggleDisplayed: boolean;

    /** Which flow the user is used to sign up. This is only meaningful when the user is just signed up until the session is refreshed. */
    SignUpTrigger: CredentialPopupTrigger;

    /** Whether the current fleet supports fixed fare bookings. */
    IsFixedFareAvailable: boolean;

    /** Whether the guest users can make bookings with credit/debit cards. */
    IsGuestCreditCardBookingEnabled: boolean;
}

/**
 * This component is for selecting payment option while creating a new booking
 */
class CardSelector extends React.Component<CardSelectorProps> {

    componentDidUpdate() {
        const option = this.props.BookingPayload.PaymentOption;
        if (!option) return;

        if (option.Kind === PaymentOptionKind.CardOrWallet && !this.props.IsPaymentSystemAvailable) {
            // update the booking payload to be 'Pay driver directly' if payment system is not available
            Dispatch.Booking.ClearPaymentOption();
        }

        // remove CNP from booking if fixed price is not selected and CNP metered is not allowed.
        if (option.Kind === PaymentOptionKind.CardOrWallet && !this.props.IsPriceGuaranteeSelected && this.props.IsCnpDisallowedByMetered) {
            Dispatch.Booking.ClearPaymentOption();
        }
    }

    /**
     * It is called when a user select a payment option from the list
     * set the selected payment option in booking store 
     */
    onPaymentMethodChange = (e: SelectChangeEvent) => {

        LogEvent.PaymentMethodChanged();        
        
        const selectedCard = this.props.PaymentOptions.find(option => option.Id === e.target.value);
        if (!selectedCard) return;

        if (selectedCard.Kind === PaymentOptionKind.PayDriverDirectly) {
            // access Moengage through the window object. MoEngage SDK is not installed with npm, it is added via GTM, so it can't be imported directly.
            if (window['Moengage']) {
                window['Moengage'].track_event('paying_the_driver_directly');
            }
        }

        Dispatch.Booking.PaymentMethod(selectedCard);
        
        Dispatch.Payment.ClearCardError();
        CheckForCardExpiry(selectedCard);

        ValidateServicesForSatss();

        // remove default payment method from local storage when the payment method is changed. if the new payment method is a wallet based payment like ApplePay or GooglePay it is stored in localstorage in a different place.
        MyStorage.DefaultPaymentMethod.ClearData();
    }

    /**
     * User clicks on Add new payment method from the dropdown
     */
    OpenCardRegistrationPanel = () => {

        // Open the signup modal for registering the guest user
        if (this.props.LoginStatus === LoginStatusKind.LoggedOut) {
            if (FeatureFlags.NoSignup) {
                // The user experience here is not confirmed yet. The ideal case is, guest users should never get up to this point.
                return;
            }

            Dispatch.Payment.ClearCardError();

            // Open Add payment card screen after signup.
            Dispatch.Payment.ShowAddPaymentCardScreenAfterSignup();
            
            LogEvent.SignupTriggeredByAddNewCard();

            new CredentialsController().SignUp(CredentialPopupTrigger.FromGuestBooking);
            return;
        }

        LogEvent.AddNewCardSelected();
        Dispatch.Payment.ToggleCardRegistrationPanel(true);                
    }    

    /**
     * Returns the payment option that is currently selected, unless there is an error with it.
     */
    GetSelectedOption: () => PaymentOption | null = () => {

        // Expired card should still be displayed in the card selector.
        if (this.props.ErrorMessage && this.props.ErrorMessage !== PaymentCardErrorType.CardExpired) return null;

        const option = this.props.BookingPayload.PaymentOption;
        if (!option) return null;

        if (option.Kind === PaymentOptionKind.CardOrWallet && !this.props.IsPaymentSystemAvailable) return PayDriverOption;

        return option;
    }

    /**
     * An informational message related to the currently selected payment option.
     * There are various scenarios where we display a tip to the user.
     */
    GetPaymentHelpText: () => string | null = () => {

        const selectedOption = this.props.BookingPayload.PaymentOption;        

        // errors take precedence
        if (this.props.ErrorMessage) return null;

        // In this particular case, only pay driver direct option is possible. Hence no message is displayed.
        if (this.props.IsCnpDisallowedByMetered && !this.props.IsFixedFareAvailable) return null;

        // When payment system (MPS, Braintree etc) is not available, the messages are different and overrides other scenarios. These are nested for code readability.
        if (!this.props.IsPaymentSystemAvailable) {

            // if fixed price is selected, this message is displayed regardless of any payment method is selected or not
            if (this.props.IsPriceGuaranteeSelected) {
                return "Online payments are temporarily unavailable. Fixed price must be switched OFF above to proceed with 'Paying the driver directly'";
            }

            // no payment method selected
            if (!selectedOption) {
                return "Online payments are temporarily unavailable. To proceed please select 'Paying the driver directly'";
            }

            // only 'cash bookings + meter price' are allowed when payment system is not available. therefore no message
            if (selectedOption.Kind === PaymentOptionKind.PayDriverDirectly) return null;
        }

        // explain how to get Fixed Price working. snackbar is hidden when the Fixed Price toggle is hidden since the user can't toggle it off and has to pay online or sign up.
        if (this.props.ShouldPromptUserToAddCard && !this.props.BookingPayload.PaymentOption && this.props.IsFixedPriceToggleDisplayed) {
            return "Add a card to book with Fixed Price.";
        }

        // if the user signed up using the pay driver direct flow, we hide this message (for the session) because he has already chosen to pay the driver directly and this message doesn't make sense.
        if (this.props.IsCnpDisallowedByMetered && this.props.SignUpTrigger !== CredentialPopupTrigger.FromPayDriverDirectLink) {
            return "For online payments, Fixed Price must be switched on above.";
        }        

        if (!selectedOption) return null;

        // special explanation for SA TTS
        if (selectedOption.Kind === PaymentOptionKind.SatssVoucher) {
            return "Provide a subsidy voucher to the driver.";
        }

        // special explanation for CNP
        if (selectedOption.Kind === PaymentOptionKind.CardOrWallet) {
            return "Payment will automatically be completed at the end of the trip."
        }

        // fixed price really doesn't support paying the driver, but we still allow the option from the UI. Instead, show an error message, but as a tip
        if (this.props.IsPriceGuaranteeSelected && (selectedOption.Kind === PaymentOptionKind.PayDriverDirectly)) {
            return "Fixed Price must be switched off above when selecting 'Paying the driver directly'"
        }
        
        return null;
    }

    /** Returns the snackbar message type (info, warning etc) based on different criteria. */
    GetMessageType: () => SnackbarType = () => {

        if (this.props.IsPriceGuaranteeSelected && (this.props.BookingPayload.PaymentOption?.Kind === PaymentOptionKind.PayDriverDirectly)) return SnackbarType.Warning;

        if (!this.props.IsPaymentSystemAvailable) return SnackbarType.Warning;

        return SnackbarType.Info;
    }

    /** Returns custom icon for the snackbar if applicable. Otherwise returns null. */
    GetCustomIcon: () => React.ReactNode | null = () => {

        if (this.props.ShouldPromptUserToAddCard && !this.props.BookingPayload.PaymentOption) return (<Icon sx={{ 'display': "flex", 'height': "16px" }}>
            <img src={getContentUrl(ContentURL.images.PaymentType.AddCard)} alt="Payment card icon" />
        </Icon>);

        return null;
    }

    render() {

        // the actual payment option
        const selectedOption = this.GetSelectedOption();

        // value for the <Select> component
        const selectedValue = selectedOption?.Id ?? "";

        const labelText = !selectedOption ? "Please select" : "";

        const paymentHelperText = this.GetPaymentHelpText();

        let snackbarType = this.GetMessageType();        

        return (
            <div className="booking-fields-panel" key={this.props.RemountCounter}>
                <FormControl fullWidth className="card-selector-dropdown" error={!!this.props.ErrorMessage}>
                    <InputLabel id="card-selector-label">{labelText}</InputLabel>
                    <Select
                        sx={{
                            height: 50
                        }}
                        value={selectedValue}
                        labelId="card-selector-label"
                        onChange={this.onPaymentMethodChange}
                        input={<OutlinedInput label={labelText} />}
                        endAdornment={
                            this.props.ErrorMessage && <InputAdornment position="end" style={{ marginRight: 0 }}>
                                <ErrorIcon fontSize="small" color="error" />
                            </InputAdornment>
                        }
                        renderValue={(selected) => {
                            if (selectedOption) {
                                // display a different UI (than the dropdown entry) of the selected option (e.g.: without Unlink button when PayPal is selected as a guest)                                
                                return this.RenderSelectOption(selectedOption, 0, false);
                            }
                            return;
                        }}
                        >
                        <AddApplePayDropDownEntry />
                        <AddGooglePayDropDownEntry />
                        <AddPayPalDropDownEntry />
                        <InlineCreditCardDropdownEntry />
                        {this.props.IsGuestCreditCardBookingEnabled && this.RenderAddCardOption() /** for guest, this is 'Cabcharge Fast Card' and should appear before pay driver direct option */}
                        {this.RenderSelectEntries()}
                        {!this.props.IsGuestCreditCardBookingEnabled && this.RenderAddCardOption()}                        
                    </Select>
                </FormControl>
                {this.props.ErrorMessage && (
                    <div className="booking-form-error-message">{this.props.ErrorMessage}
                    </div>
                )}
                {paymentHelperText && (
                    <div className="payment-help-text">
                        <SnackBar DisplayText={paymentHelperText} Type={snackbarType} CustomIcon={this.GetCustomIcon()} />
                    </div>
                )}
            </div>
        );
    }

    /**
     * Renders the normal entries in the dropdown list, backed by the existing payment methods available to this user. 
     */
    RenderSelectEntries() {

        let entries = this.props.PaymentOptions;

        if (this.props.IsCnpDisallowedByMetered || !this.props.IsPaymentSystemAvailable) {
            entries = entries.filter(i => i.Kind != PaymentOptionKind.CardOrWallet);
        }

        return entries.map((option: PaymentOption, index: number) => {
            return this.RenderSelectOption(option, index, true);
        });
    }

    /** Renders a single option. Selected option's UI may be different in the dropdown entry (vs the UI in the select input). */
    RenderSelectOption(option: PaymentOption, index: number, isForDropdownEntry: boolean) {
        return <MenuItem className="cardMenuItem" key={index} value={option.Id} disableGutters={true}>
            <div style={{ "display": "grid" }}>
                <img className="cardSelectorCardImage" src={getContentUrl(ContentURL.images.PaymentType.Selector[option.Type])} alt={option.Type + " icon"} />
                {this.PaymentOptionDisplayNameUi(option, isForDropdownEntry)}
            </div>
        </MenuItem>;
    }

    /** Derive the display text UI for each card in the card selector. */
    PaymentOptionDisplayNameUi(option: PaymentOption, isForDropdownEntry: boolean) {

        if (option.Type === CnpPaymentKind.PayPal) {

            // guest users
            if (this.props.PayPalTokenised) return <>
                <span className="cardSelectorCardName cardSelectorEmail">
                    <span className="emailDisplay">
                        {this.props.PayPalTokenised.details.email}
                    </span>
                    {isForDropdownEntry && <span className="unlink-btn" onClick={() => PayPal.OpenUnlinkDialog()}>Unlink</span>}
                </span>                
            </>;

            // eventually this should be the email associated with the linked PayPal account for signed in users too. It is not available yet with the API response. This is only a temporary workaround and need to be updated once the API is updated.
            return <span className="cardSelectorCardName">PayPal (Linked)</span>;
        }

        return <span className="cardSelectorCardName">{option.Name}</span>;
    }

    /**
     * Renders the "Add card" entry in the dropdown list.
     * Only when CNP is supported.
     */
    RenderAddCardOption = () => {

        if (!FeatureFlags.CardNotPresentPayment) return null;

        if (this.props.IsCnpDisallowedByMetered) return null;

        if (!this.props.IsPaymentSystemAvailable) return null;

        let optionText = "Add card";
        let optionIcon = getContentUrl(ContentURL.images.buttons.addIconBlack);
        let imageClass = "cardSelectorAddCardImage";

        if(this.props.IsGuestCreditCardBookingEnabled) {
            optionText = "Cabcharge Fastcard";
            optionIcon = getContentUrl(ContentURL.images.CnpPaymentMethod.Cabcharge);
            imageClass = "cardSelectorCardImage";
        }

        return (<MenuItem className="cardMenuItem" onClick={this.OpenCardRegistrationPanel} value="#" disableGutters={true} selected={false}>
            <img className={imageClass} src={optionIcon} alt={optionText + " icon"} />
            <span className="cardSelectorCardName">{optionText}</span>
        </MenuItem>);
    }
}

function mapStateToProps(state: ApplicationState): CardSelectorProps {
    return {
        BookingPayload: state.booking,
        ErrorMessage: state.payment.PaymentCardError,
        LoginStatus: state.authentication.LoginStatus,
        PaymentOptions: PopulatePaymentOptions(state),
        ShouldPromptUserToAddCard: ShouldPromptUserToAddCard(state),
        IsPriceGuaranteeSelected: state.condition.IsPriceGuaranteeSelected,
        PayPalTokenised: state.GuestPayment.PayPalTokenised,
        RemountCounter: state.uiLogicControl.CardSelectorRemountCounter,
        IsCnpDisallowedByMetered: OptionalUI.CnpDisallowedByMetered(state),
        IsPaymentSystemAvailable: state.payment.IsPaymentSystemAvailable,
        IsFixedPriceToggleDisplayed: OptionalUI.FixedPriceToggle(state),
        SignUpTrigger: state.uiLogicControl.Credentials.PopupTriggerSource,
        IsFixedFareAvailable: state.condition.IsFixedFareAvailable,
        IsGuestCreditCardBookingEnabled: OptionalUI.GuestCreditCard(state),
    };
}

export default connect(mapStateToProps)(CardSelector);
