import React, { Component } from "react";
import { getContentUrl, ContentURL } from "../../../modules/Utils/ContentURL";
import "./selected-vehicle-bar.scss";
import services, { ServiceKind } from "../../../utils/service-vehicles/ServiceMetadata";
import { VehicleOption, DataLoadingStatus } from "../Redux/ConditionEntities";
import { GetFareDisplayText } from "../../Fare/FareHelper";
import { Dispatch } from "../../Dispatch";
import "./ConditionView.scss";
import { ApplicationState } from "../../../appState";
import { connect } from "react-redux";
import { IsValidFareDataAvailable, IsFixedFareAvailable } from "../ShouldDisplayFare";
import { Tooltip, Grid, Box, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import cn from "classnames";
import { DialogKind } from "../../Dialog/DialogEntities";
import { BrandedImage, GetBrandedUrl } from "../../Utils/BrandedContentUrls";
import { OptionalUI } from "../../Booking/OptionalParts/OptionalUI";

interface VehicleBarProps {
    ServiceDetails: VehicleOption;
    IsTemplateModeOn: boolean;
    ClickHandler: Function;

    /** Used to enable specific styles to the last element of the list. */
    IsLastElement?: boolean;
}

interface PropsFromStore {
    SelectedCondition: VehicleOption;

    /** Whether the UI is prepared to display fare estimates. */
    IsFareDataAvailable: boolean;

    /** Whether fixed fare pricing option is available in this location */
    IsFixedFareAvailable: boolean;

    /** The on/off value of the Price Guarantee (fixed fare) toggle switch */
    IsPriceGuaranteeSelected: boolean;

    /** Whether the PG Fee is supported by the selected payment method */
    DoesPgFeeApply: boolean;

    /** Progress of loading the fare estimate (API call) */
    FareLoadStatus: DataLoadingStatus;

    /** Whether the vehicle selector is expanded or not */
    IsVehicleSelectionActive: boolean;
}

/** Component to display basic condition details. This component is included in different other components. */
class ConditionView extends Component<VehicleBarProps & PropsFromStore> {
    constructor(props: VehicleBarProps & PropsFromStore) {
        super(props);

        this.OpenServiceInfo = this.OpenServiceInfo.bind(this);
    }

    /** Open the popup to display more info about the service. */
    OpenServiceInfo(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        Dispatch.Condition.ActiveService(this.props.ServiceDetails);
        Dispatch.Condition.ShowServiceInfo();

        if (this.props.ServiceDetails.Service.kind === ServiceKind.MaxiTaxi) {
            Dispatch.Dialog.ShowDialog(DialogKind.MaxiTaxiSeatGuide);
        }
    }

    /** Derives the short description of the service (to be displayed below the service name). */
    DeriveShortDescription(): string {
        if (this.props.ServiceDetails.Service.kind === ServiceKind.Parcel) return "Fits in a car";
        if (this.props.ServiceDetails.Service.kind === ServiceKind.MaxiTaxi) {
            if (this.props.SelectedCondition.ApiVehicle) {
                if (this.props.SelectedCondition.Service.kind === ServiceKind.MaxiTaxi) {
                    return this.props.SelectedCondition.ApiVehicle.Description;
                }
            }
        }

        return this.props.ServiceDetails.Service.short;
    }

    /** Decides whether the current vehicle is the selected vehicle in order to do custom CSS.
     * Not applicable when the vehicle selector is collapsed. */
    IsSelectedService(): boolean {
        // for V1 API vehicles
        if (this.props.ServiceDetails.Service.kind && this.props.SelectedCondition.Service.kind === this.props.ServiceDetails.Service.kind) return true;

        // for V2 API vehicles
        if (this.props.SelectedCondition.ApiVehicle?.ApiId === this.props.ServiceDetails.ApiVehicle?.ApiId) return true;

        return false;
    }

    /** Gets the text to display in the fare estimate space */
    GetFareText(): string {
        // warning: this code is basically wrong. There are more reasons possible, e.g. destination might be populated but pickup could be missing
        // also "fare loading in progress"
        // it is left as is for now to match the previous implementation
        if (!this.props.IsFareDataAvailable) return "Dest required";

        // this is a new case. Not really expected to occur. It requires a mismatch between available conditions from the Booking API vs available fare tariff vehicle types from the fare estimator API (i.e. Taxi Rate Service)
        if (!this.props.ServiceDetails.FareDetails) {
            if (this.props.FareLoadStatus == DataLoadingStatus.InProgress) {
                return "Loading...";
            }

            return "Data unavailable";
        }

        const isFixedFare = this.props.IsFixedFareAvailable && this.props.IsPriceGuaranteeSelected;

        return GetFareDisplayText(this.props.ServiceDetails.FareDetails, isFixedFare, this.props.DoesPgFeeApply);
    }

    HandleOnKeyDown(e: React.KeyboardEvent) {
        // Prevent the click event from firing when the vehicle selector is collapsed since we need to open the vehicle selector instead of selecting the vehicle in this case.
        if (!this.props.IsVehicleSelectionActive) {
            return;
        }

        if (e.key !== 'Tab') {
            this.props.ClickHandler();
        }
    }

    render() {
        const vehicleImg = this.props.ServiceDetails.Service.isAny === false ? this.props.ServiceDetails.Service.image : services.sedan.image;
        const parcelImageClass = this.props.ServiceDetails.Service.kind === ServiceKind.Parcel && "selected-parcel-image";

        const displayFare = this.GetFareText();

        const fareLabel = this.props.IsPriceGuaranteeSelected ? "Fixed Price" : "Fare Estimate";

        const shortDescription = this.DeriveShortDescription();

        return (
            <Box
                className={cn("mobile-condition-container", {
                    "expanded": this.props.IsVehicleSelectionActive,
                    "highlight-selected-service": this.IsSelectedService()
                })}
                onClick={() => this.props.ClickHandler()}
                tabIndex={0}
                onKeyDown={(e) => this.HandleOnKeyDown(e)}
            >
                <Box
                    sx={{
                        width: 8,
                        height: 80,
                        position: "absolute",
                        left: 0,
                        backgroundColor: t => t.palette.primary.main,
                        display: `${this.IsSelectedService() ? "block" : "none"}`
                    }}
                />
                <Box className="mobile-condition-content" margin="auto" width="90%" justifyContent="center">
                    <Grid xs item container direction="row" alignItems="center" columnSpacing={{ xs: 4, sm: 8 }} wrap="nowrap">
                        <Grid item className={`selected-vehicle-bar-image ${parcelImageClass}`} sx={{ position: "relative" }}>
                            <img
                                src={vehicleImg}
                                alt={this.props.ServiceDetails.Service.displayName}
                                style={{
                                    width: 88,
                                    height: "auto",
                                    marginLeft: 0
                                }}
                            />
                            <Tooltip title="View information about this service" arrow onClick={this.OpenServiceInfo}>
                                <InfoIcon
                                    color="action"
                                    sx={{
                                        fontSize: "1rem",
                                        display: {
                                            xs: "inline-block",
                                            sm: "none"
                                        },
                                        position: "absolute",
                                        top: 0,
                                        right: 0
                                    }}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid xs item container justifyContent="space-between" wrap="nowrap">
                            <Grid item container direction="column" justifyContent="space-between" rowSpacing={2} sx={{ whiteSpace: "nowrap" }}>
                                <Grid item container columnSpacing={2}>
                                    <Grid item>
                                        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                            {this.props.ServiceDetails.Service.displayName}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        display="flex"
                                        padding={1}
                                        sx={{
                                            display: { xs: "none", sm: "flex" }
                                        }}
                                    >
                                        <Tooltip title="View information about this service" arrow onClick={this.OpenServiceInfo}>
                                            <InfoIcon color="action" sx={{ fontSize: "1rem" }} />
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2" className="font_helvetica" color={t => t.palette.text.primary}>
                                        {shortDescription}
                                    </Typography>
                                </Grid>
                            </Grid>
                            {!this.props.IsTemplateModeOn && (
                                <Grid item container direction="column" rowSpacing={2} alignItems="flex-end">
                                    <Grid item>
                                        <Typography variant="body2" className="font_helvetica">
                                            {fareLabel}
                                        </Typography>
                                    </Grid>
                                    <Grid item container columnSpacing={{xs: 0, sm: 2}} justifyContent="flex-end" alignItems="flex-end" wrap="nowrap" sx={{ whiteSpace: "nowrap" }}>
                                        <Grid item display="flex">
                                            <img
                                                className={cn("fe-lock", {"fe-lock-hidden": !this.props.IsPriceGuaranteeSelected})}
                                                src={GetBrandedUrl(BrandedImage.LockIcon)}
                                                alt="Fixed fare"
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography
                                                variant="body1"
                                                className="font_helvetica"
                                                sx={{
                                                    color: t => (this.props.IsFareDataAvailable ? t.palette.common.black : t.palette.text.primary),
                                                    fontWeight: this.IsSelectedService() ? "bold" : "",
                                                    lineHeight: "1rem",
                                                    fontSize: this.props.IsFareDataAvailable ? "1rem" : ".875rem"
                                                }}
                                            >
                                                {displayFare}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    {this.props.FareLoadStatus === DataLoadingStatus.InProgress && <img src={getContentUrl(ContentURL.images.Loading)} alt="Loading" className="fare-loading" />}
                </Box>
            </Box>
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        IsFareDataAvailable: IsValidFareDataAvailable(state),
        IsFixedFareAvailable: IsFixedFareAvailable(state),
        SelectedCondition: state.condition.SelectedCondition,
        IsPriceGuaranteeSelected: state.condition.IsPriceGuaranteeSelected,
        DoesPgFeeApply: OptionalUI.PgFee(state),
        FareLoadStatus: state.condition.FareLoadStatus.Status,
        IsVehicleSelectionActive: state.condition.IsVehicleSelectionActive
    };
}

export default connect(mapStateToProps)(ConditionView);
