import { Chip, Switch, Typography } from '@mui/material';
import React from 'react'
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { ApplicationState } from '../../appState';
import { FeatureInfo } from '../../Services/FeaturesContracts';
import { OptionalUI } from '../Booking/OptionalParts/OptionalUI';
import { CommonDialogHeaderCore } from '../Dialog/CommonDialogHeader';
import { DialogKind } from '../Dialog/DialogEntities';
import { BrandedImage, GetBrandedUrl } from '../Utils/BrandedContentUrls';
import { ContentURL, getContentUrl } from '../Utils/ContentURL';
import "./AvailablePreviews.scss";
import { PreviewMetadata, PreviewFeatureId, LookupPreviewMetadata } from './FeatureEntities';
import { PreviewFeatures } from './PreviewFeatures';

/** 
 * A list of preview features available for user optin.
 * Displays as a tab in the user's profile page.
 */
const AvailablePreviewsCore: React.FC<ReduxProps> = (props) => {

    const usableFeatures = props.PreviewFeatures
        .filter(i => i.IsUserOptable)
        .map(ExtendWithMetadata)
        .filter(IsFeatureVisibleToMe);

    return (
        <div className="available-features-main-area">
            <CommonDialogHeaderCore TopmostDialog={DialogKind.AvailableFeatures} />
            <div className='available-features-description'>
                <span>Manage early access to new features and adopt changes on timeline that works for you.</span>
                <span>You can turn the status on or off for your selected feature.</span>
            </div>
            <div className="available-features-content-area">
                <Table className="available-features-title-area" responsive>
                    <thead className="features-heading">
                        <tr>
                            <th className="features-title">Feature</th>
                            <th className="description-title">Description</th>
                            <th className="status-title">Status</th>
                        </tr>
                    </thead>
                    <tbody className="available-features-body-area">
                        {usableFeatures.map(feature => (
                            <FeatureTableRow key={feature.ServerData.FeatureId} {...feature} />
                        ))}
                    </tbody>
                    <tr><td colSpan={4}><hr className="Dividing-line"></hr></td></tr>
                </Table>
            </div>
        </div>
    );

    /**
     * Client-side checks about whether to display a feature.
     */
    function IsFeatureVisibleToMe(feature: AllFeatureData): boolean {

        // always give the off option if it is currently on. For safety. 
        if (feature.ServerData.IsEnabled) return true;

        // account only restriction
        if (feature.ClientMetadata.AccountUsersOnly && !props.IsAccountUser) return false;

        return true;
    }
}

interface AllFeatureData {
    /** The API's view of the feature: IsOptable, IsEnabled */
    ServerData: FeatureInfo,

    /** The client's knowledge about a feature: name, description, etc */
    ClientMetadata: PreviewMetadata,
}

/**
 * Upgrade a server-specified feature by adding the client's known metadata about it.
 */
function ExtendWithMetadata(feature: FeatureInfo): AllFeatureData {

    // this lookup is known to work because all the FeatureInfos in this class have previously been looked up
    var metadata: PreviewMetadata = LookupPreviewMetadata[feature.FeatureId as PreviewFeatureId];

    return {
        ServerData: feature,
        ClientMetadata: metadata,
    };
}

/**
 * A single row in the preview feature opt-in table.
 */
const FeatureTableRow: React.FC<AllFeatureData> = (feature) => {

    const imgUrl = feature.ServerData.IsEnabled ? GetBrandedUrl(BrandedImage.PickupAddressA) : getContentUrl(ContentURL.images.UserProfile.CircleGrey);

    return (
        <tr>
            <td>
                <div className='feature-name-container'>
                    <div className='feature-name'>
                        <img src={imgUrl} alt="featureIcon" />
                        <Typography>{feature.ClientMetadata.DisplayName}</Typography>
                        <Chip label="NEW" />
                    </div>
                </div>
            </td>
            <td>
                <Typography>{feature.ClientMetadata.Description}</Typography>
            </td>
            <td className="status-body">
                <Switch color="primary" checked={feature.ServerData.IsEnabled} onChange={() => PreviewFeatures.ToggleOptInState(feature.ServerData)} inputProps={{ 'aria-label': 'Enable/disable preview' }} />
            </td>
        </tr>
    );
}

// #region Redux

interface ReduxProps {

    /** Features that are understood by the client. */
    PreviewFeatures: FeatureInfo[],

    /** True if the user is signed in and has access to accounts. */
    IsAccountUser: boolean,
}

function GetPropsFromRedux(state: ApplicationState): ReduxProps {

    return {
        PreviewFeatures: state.features.PreviewFeatures,
        IsAccountUser: OptionalUI.CanBookOnAccount(state),
    };
}

export const AvailablePreviews = connect(GetPropsFromRedux)(AvailablePreviewsCore);

// #endregion