import { Button } from "@mui/material";
import React, { useState } from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../../../appState";
import { DialogKind } from "../../Dialog/DialogEntities";
import { Dispatch } from "../../Dispatch";
import { ContentURL, getContentUrl } from "../../Utils/ContentURL";
import { CustomErrorMessages } from "../../Utils/ErrorMessages";
import { AddressPointModalType, AddressPoint } from "../Redux/AddressPointsEntities";
import { SaveAddressPoint } from "../SaveAddressPoint";

interface PropsFromStore {
    ActiveAddressPoint: AddressPoint;
    UiEditMode: AddressPointModalType;
    ErrorMessage: string | null;
}

/** Component contains action buttons in create/edit address point modals. */
const AddressPointActionsCore: React.FC<PropsFromStore> = (props) => {

    // Whether the save address is in progress (API operation is running). Used to disable the Save button and display a spinner.
    const [isSavingInProgress, SetSavingInProgress] = useState(false);

    /** Create or Edit an address point based on the currently opened window type. */
    async function CreateOrUpdateAddressPoint() {
        SetSavingInProgress(true);
        const result = await SaveAddressPoint();
        SetSavingInProgress(false);

        // Don't close the modal but display error message if there is an error saving address point.
        if (!result) {
            Dispatch.AddressPoints.RefreshAddressPointError(CustomErrorMessages.SaveAddressPointFailed);
            return;
        }

        ClearAddressPointDataAndCloseDialog();

        // Show confirmation dialog only after create action.
        if (props.UiEditMode === AddressPointModalType.Create) {
            Dispatch.Dialog.ShowDialog(DialogKind.CreateAddressConfirmation);
        }
    }

    /** Decides whether to enable/disable the 'Save/Update' button based on different criteria. */
    function ShouldEnableSaveButton() {
        if (isSavingInProgress) return false;
        if (!props.ActiveAddressPoint.Address) return false;
        if (!props.ActiveAddressPoint.Name) return false;

        return true;
    }

    const buttonLabel = props.UiEditMode === AddressPointModalType.Create ? "Save address" : "Update address";

    return (
        <div className="address-point-footer">
            <div className="address-point-error">{props.ErrorMessage}</div>
            <div className="address-points-actions-panel">
                <Button variant="outlined"
                    onClick={ClearAddressPointDataAndCloseDialog}>
                    Cancel
                </Button>
                <Button variant="contained"
                    size="medium"
                    color="primary"
                    disabled={!ShouldEnableSaveButton()}
                    onClick={CreateOrUpdateAddressPoint}>
                    {buttonLabel}
                    {isSavingInProgress && <img src={getContentUrl(ContentURL.images.Loading)} className="save-address-loading" alt="Loading.."></img>}
                </Button>
            </div>
        </div>
    );
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        ActiveAddressPoint: state.addressPoints.ActiveAddressPoint,
        UiEditMode: state.addressPoints.UiEditMode!, // Non-null when this component is mounted.
        ErrorMessage: state.addressPoints.ErrorMessage
    }
}

export const AddressPointActions = connect(mapStateToProps)(AddressPointActionsCore);

/** Close current dialog and clear ActiveAddressPoint data on click of Cancel or Close dialog. This is exported to be used in DialogConfig as the callback function for Close button. */
export function ClearAddressPointDataAndCloseDialog() {
    Dispatch.AddressPoints.ClearActiveAddressPoint();
    Dispatch.AddressPoints.ClearUiEditModeType();
    Dispatch.AddressPoints.ClearAddressPointError();
    Dispatch.Dialog.CloseTopDialog();
}