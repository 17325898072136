import React, { Component } from 'react';
import { connect } from "react-redux";
import services, { ServiceKind } from '../../../utils/service-vehicles/ServiceMetadata';
import { Dispatch } from '../../Dispatch';
import { BookingWidgetModeKind } from "../../BookingTemplate/BookingTemplateEntities";
import { VehicleOption, MaxiTaxiLookUpType, MaxiTaxiSeaterInfoPayload } from '../Redux/ConditionEntities';
import { ApplicationState } from '../../../appState';
import ConditionView from './ConditionView';
import { ProcessAndValidateSelectedService } from '../ProcessAndValidateSelectedCondition';
import { PreviewFeatureId } from '../../Features/FeatureEntities';
import { DialogKind } from '../../Dialog/DialogEntities';

interface PropsFromStore {
    ConditionList: VehicleOption[];
    MaxiTaxiLookUp: MaxiTaxiLookUpType;
    MaxiTaxiSeaterInfo: MaxiTaxiSeaterInfoPayload;
    SelectedCondition: VehicleOption;
    BookingWidgetMode: BookingWidgetModeKind;
    IsPriceGuaranteeSelected: boolean;
}

interface MyState {
    clickedIndex: number;
    focusedVehicle: VehicleOption;
    clickedSeaterNo: number;
}

/** Component to select a service. Lists all the available services as an inline component in the booking widget.  */
class ConditionSelector extends Component<PropsFromStore, MyState> {

    constructor(props: PropsFromStore) {
        super(props);

        this.state = {
            clickedIndex: 0,
            focusedVehicle: { Service: services.any },
            clickedSeaterNo: 0
        };

        this.confirmSelectedVehicle = this.confirmSelectedVehicle.bind(this);
    }

    static getDerivedStateFromProps(nextProps: PropsFromStore) {

        const selectedMaxiTaxiSeaterNo = nextProps.SelectedCondition.Service.isMaxiTaxi ? nextProps.SelectedCondition?.ApiVehicle?.MaxSeat : nextProps.MaxiTaxiSeaterInfo.Average;
        if (selectedMaxiTaxiSeaterNo !== 0) {
            return { clickedSeaterNo: selectedMaxiTaxiSeaterNo };
        }
        else return null;
    }

    // Update the store with the selected service and close the services list.
    confirmSelectedVehicle(selectedIndex: string) {

        let selectedVehicle: VehicleOption = this.props.ConditionList[selectedIndex];

        if (selectedVehicle.Service.isMaxiTaxi) {
            Dispatch.Condition.ActiveService(selectedVehicle);
            Dispatch.Condition.ShowServiceInfo();
            Dispatch.Dialog.ShowDialog(DialogKind.MaxiTaxiSeatGuide);

            const condition = this.props.MaxiTaxiLookUp[this.state.clickedSeaterNo];

            if (condition) {
                selectedVehicle = {
                    ...selectedVehicle,
                    ApiVehicle: condition
                };
            }
        }

        ProcessAndValidateSelectedService(selectedVehicle);
        Dispatch.Condition.CollapseVehicleSelectorUI();

        // after a vehicle is selected with tab navigation, the focus goes back to the 'Fixed Price' toggle and then stays in a loop between the toggle and the vehicle selecter. Therefore, manually focus the contact name field once a vehicle is selected.
        Dispatch.UILogicControl.ShouldContactNameFocused(true);
    }

    render() {
        let vehicles = [];    

        for (let key in this.props.ConditionList) {
            const perVehicle = this.props.ConditionList[key];

            vehicles.push(<ConditionView
                key={key}
                ServiceDetails={perVehicle}
                IsTemplateModeOn={this.props.BookingWidgetMode !== BookingWidgetModeKind.Booking}
                ClickHandler={() => this.confirmSelectedVehicle(key)}
                IsLastElement={parseInt(key) === this.props.ConditionList.length - 1}
            />);
        }

        return (
            <div className="service-selector">
                {vehicles}
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {

    let vehicleOptions = state.condition.ConditionList;

    // this feature moves the parcel option to a custom selector at the top of the booking form
    if (state.features.EnabledPreviews[PreviewFeatureId.ParcelDeliveryWidget]) {
        vehicleOptions = vehicleOptions.filter(vehicle => vehicle.Service.kind !== ServiceKind.Parcel);
    }

    return {
        ConditionList: vehicleOptions,
        MaxiTaxiLookUp: state.condition.MaxiTaxiLookUp,
        MaxiTaxiSeaterInfo: state.condition.MaxiTaxiSeaterInfo,
        SelectedCondition: state.condition.SelectedCondition,
        BookingWidgetMode: state.uiLogicControl.BookingForm.BookingWidgetMode,
        IsPriceGuaranteeSelected: state.condition.IsPriceGuaranteeSelected
    };
}

export default connect(mapStateToProps)(ConditionSelector);