import * as React from 'react';
import { ContentURL, getContentUrl } from '../../Utils/ContentURL';
import { AddressPickerEntry } from '../Entities/AddressPickerEntry';
import { AddressDropdownEntry } from './AddressDropdownEntry';
import "./AddressPickerV2.scss";

/**
 * Part of the Address Picker component.
 * This renders the dropdown list that displays when the user is typing into the text field.
 */
export const AddressDropdownList: React.FC<MyProps> = (props) => {

    return (
        <div className="address-list-popup">
            {props.ListItems.map((entry, index) => (
                <AddressDropdownEntry
                    key={index}
                    Entry={entry}
                    InputText={props.InputText}
                    OnItemSelected={props.OnItemSelected}
                    IsTargeted={index === props.TargetedRowIndex}
                />
            ))}

            <img src={getContentUrl(ContentURL.images.address.PoweredByGoogle)} className="google-logo" alt="Powered by Google logo"></img>
        </div>
    );
}

/** 
 *  Props for the entire dropdown list of address suggestions.
 */
interface MyProps {

    /** Text the user has typed in. Used to highlight matches */
    InputText: string;

    /** suggested addresses to display in the list */
    ListItems: AddressPickerEntry[];

    /** Callback when the user selects an entry from the list */
    OnItemSelected: (selectedItem: AddressPickerEntry) => void;

    /** 
     *  Index of the row that has was targeted by the keyboard (from an arrow down event).
     *  This row should get extra highlighting.
     *  Normally null until the user presses the arrow keys.
     */
    TargetedRowIndex: number | null;
}