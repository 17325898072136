import React, { useEffect } from "react";
import { Dispatch } from "../Dispatch";
import { MenuItem } from "@mui/material";
import { ContentURL, getContentUrl } from "../Utils/ContentURL";
import { GooglePayPlaceholder } from "../Payment/PaymentEntities";
import { OptionalUI } from "../Booking/OptionalParts/OptionalUI";
import { useAppState } from "../../Redux/ReduxHooks";
import { SdkLoadingState } from "../PayPal/PayPalState";
import { ConsiderGuestPaymentReset } from "../GuestPayment/ConsiderGuestPaymentReset";

/** 
 * Renders the Add Google Pay entry in the payment method dropdown in the booking form.
 * Only present when the feature is supported and the device/browser allows Google Pay payments.
 */
export const AddGooglePayDropDownEntry: React.FC = () => {

    const isFeatureEnabled = useAppState(OptionalUI.AddGooglePay);
    const isCnpDisallowed = useAppState(OptionalUI.CnpDisallowedByMetered);
    const isGooglePaySupported = useAppState(i => i.GooglePay.IsGooglePaySupported);
    const sdkLoadStatus = useAppState(i => i.GooglePay.LoadingStatus);
    const isPaymentSystemAvailable = useAppState(i => i.payment.IsPaymentSystemAvailable);

    // when the user opens the Payment dropdown very early (i.e. before entering the pickup address)
    useEffect(() => {

        // Google Pay is not supported
        if (!isFeatureEnabled) return;

        if (sdkLoadStatus === SdkLoadingState.NotRequested) {
            Dispatch.GooglePay.ReadyToLoad();
        }
    }, []);

    // feature is disabled    
    if (!isFeatureEnabled) return null;
    if (isCnpDisallowed) return null;
    if (!isPaymentSystemAvailable) return null;

    // current device/browser doesn't support Google pay.
    if (!isGooglePaySupported) return null;

    return (
        <MenuItem className="cardMenuItem" value="#" disableGutters
            onClick={OnGooglePayButtonClicked}
        >
            <img className="cardSelectorCardImage" src={getContentUrl(ContentURL.images.PaymentType.Selector.GooglePay)} alt="Google pay icon" />
            <span className="cardSelectorCardName">Google Pay</span>
        </MenuItem>
    );
}

/** Event handler for clicking on the Google Pay menu item. */
function OnGooglePayButtonClicked() {

    Dispatch.Payment.ClearCardError();
    Dispatch.UILogicControl.CloseCardSelectorDropdown();
    Dispatch.Booking.PaymentMethod(GooglePayPlaceholder);

    // clear any other guest payment method
    ConsiderGuestPaymentReset();
}