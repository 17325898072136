import React from "react";
import { OptionalUI } from "../Booking/OptionalParts/OptionalUI";
import { useAppState } from "../../Redux/ReduxHooks";
import { Dispatch } from "../Dispatch";
import { MenuItem } from "@mui/material";
import { ContentURL, getContentUrl } from "../Utils/ContentURL";
import { CreditCardPlaceholder } from "../Payment/PaymentEntities";
import { ConsiderGuestPaymentReset } from "../GuestPayment/ConsiderGuestPaymentReset";

/** 
 * Renders the Credit/Debit card entry in the payment method dropdown in the booking form which would render the inline credit card form.
 * Only present when the feature is supported and the user is not signed in.
 */
export const InlineCreditCardDropdownEntry: React.FC = () => {

    const isFeatureEnabled = useAppState(OptionalUI.GuestCreditCard);
    const isCnpDisallowed = useAppState(OptionalUI.CnpDisallowedByMetered);
    const isPaymentSystemAvailable = useAppState(i => i.payment.IsPaymentSystemAvailable);

    // not supported
    if (!isFeatureEnabled) return null;
    if (isCnpDisallowed) return null;
    if (!isPaymentSystemAvailable) return null;

    return (
        <MenuItem className="cardMenuItem" value="#" disableGutters
            onClick={OnAddCreditCardSelected}
        >
            <img className="cardSelectorCardImage" src={getContentUrl(ContentURL.images.PaymentType.Selector.CreditOrDebit)} alt="Payment card image" />
            <span className="cardSelectorCardName">Credit/Debit card</span>
        </MenuItem>
    );
}

/** Event handler for clicking on the Credit/Debit card menu item. */
function OnAddCreditCardSelected() {

    Dispatch.Payment.ClearCardError();
    Dispatch.UILogicControl.CloseCardSelectorDropdown();
    Dispatch.Booking.PaymentMethod(CreditCardPlaceholder);

    // clear any other guest payment method
    ConsiderGuestPaymentReset();
}