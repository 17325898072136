import React, { FC } from "react";
import { connect } from "react-redux";
import { Grid, Typography, Box } from "@mui/material";
import { ApplicationState } from "../../appState";
import { LoginStatusKind } from "../Authentication/AuthEntities";
import { UpdatePriceGuaranteeStatus } from "./PriceGuaranteeToggleHandler";
import { IsFixedFareAvailable } from "../Condition/ShouldDisplayFare";
import IosSwitch from "../Switch/IosSwitch";
import { OptionalUI } from "../Booking/OptionalParts/OptionalUI";
import { BrandedImage, GetBrandedUrl } from "../Utils/BrandedContentUrls";

export interface PriceGuaranteeToggleProps {
    LoginStatus: LoginStatusKind;
    IsPriceGuaranteeSelected: boolean;
    IsFixedFareAvailable: boolean;

    // whether to show/hide only the toggle switch, but keep the rest of the panel visible. Happens only in some specific cases.
    ShouldShowToggle: boolean;
}

/** Displays the toggle control to switch between the fixed and estimated fare on the vehicle selector. */
const PriceGuaranteeToggle: FC<PriceGuaranteeToggleProps> = props => {

    const infoText = props.ShouldShowToggle ? "Lock in a price with no additional charges." : "The price is locked in with no additional charges.";

    const HandleOnKeyDown = (event: React.KeyboardEvent) => {
        if (event.key !== 'Tab') {
            UpdatePriceGuaranteeStatus(props);
        }
    }

    return (
        <Box
            sx={{
                py: "1rem",
                borderTop: "1px solid #ddd",
                backgroundColor: "#F8F6F2"
            }}
        >
            <Grid container sx={{ width: "90%", margin: "auto" }} rowSpacing={2}>
                <Grid item container direction="row" justifyContent="space-between" alignItems="center" wrap="nowrap">
                    <Grid item container direction="row" columnSpacing={4} alignItems="center">
                        <Grid item display="flex">
                            <img className="fe-lock" src={GetBrandedUrl(BrandedImage.LockIcon)} alt="Lock icon" />
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" sx={{ fontWeight: "bold" }} color="primary">
                                Fixed Price
                            </Typography>
                        </Grid>
                    </Grid>
                    {props.ShouldShowToggle && <Grid item display="flex" alignItems="flex-end">
                        <IosSwitch checked={props.IsPriceGuaranteeSelected} onChange={() => UpdatePriceGuaranteeStatus(props)} tabIndex={0} onKeyDown={(e) => HandleOnKeyDown(e)} />
                    </Grid>}
                </Grid>
                <Grid item>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {infoText}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

function mapStateToProps(state: ApplicationState): PriceGuaranteeToggleProps {
    return {
        LoginStatus: state.authentication.LoginStatus,
        IsPriceGuaranteeSelected: state.condition.IsPriceGuaranteeSelected,
        IsFixedFareAvailable: IsFixedFareAvailable(state),
        ShouldShowToggle: OptionalUI.FixedPriceToggle(state),
    };
}

export default connect(mapStateToProps)(PriceGuaranteeToggle);
