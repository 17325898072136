import React, { useEffect } from "react";
import "./ExpandedSection.scss";
import { BookingDataOwnership, BookingStatus } from "../../../Services/BookingEntities";
import { ContentURL, getContentUrl } from "../../Utils/ContentURL";
import { GetBrandedUrl, BrandedImage } from "../../Utils/BrandedContentUrls";
import { TryCancelTheBooking, ShareTheBooking, RemoveBookingCard } from "../Logic/OperateOnBookingCard";
import { PopulateSelectedTripDetails } from "../../Booking/QuickBook/PopulateSelectedTripDetails";
import { RecentTripButtonKind } from "../../UILogicControl/UILogicControlEntities";
import { QuickBookSource } from "../../Booking/QuickBook/QuickBookEntities";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { RouteUrls } from "../../../RouteUrls";
import { GetValues } from "../../../Config/MyAppConfig";
import { ConsiderRefreshingPODImageUrls, PerformPODImageUrlRefresh } from "../PodRefresh/RefreshPODImagesUrls";
import { ShouldWatchForPodImageUrls } from "../PodRefresh/PodRefreshValidations";
import { ProofOfDeliveryImageCarouselProps, ProofOfDeliveryImageContainer } from "./ProofOfDeliveryImageContainer";
import { PollingIntervalInMillis, } from "../PodRefresh/PodRefreshParameters";
import { BookingInfo } from "../MyBookingEntities";
import { TaxiTrackingGoogleMap } from "../../../widgets/google-maps/MapComponents/TaxiTrackingGoogleMap";
import { SnackBar } from "../../../widgets/InformationDisplay/Snackbar";
import { SnackbarType } from "../../../widgets/InformationDisplay/SnackbarEntities";

export interface BookingTrackingProps {
    BookingDetails: BookingInfo;
}

/** Component contains the expanded section of a specific booking. Displaying additional details about the booking such as address details, vehicle type etc. */
export const ExpandedSection: React.FC<BookingTrackingProps & ProofOfDeliveryImageCarouselProps> = (props) => {

    /** Set the timer for loading the proof of delivery images for delivery bookings */
    useEffect(() => {

        if (!ShouldWatchForPodImageUrls(props.BookingDetails)) return;

        // Call the API to fetch the image urls whenever the booking card is expanded
        PerformPODImageUrlRefresh(props.BookingDetails);        

        var interval = setInterval(async () => await ConsiderRefreshingPODImageUrls(props.BookingDetails), PollingIntervalInMillis);

        return () => clearInterval(interval);
             
    }, []);

    return (
        <div className="expanded-section">
            <ProofOfDeliveryImageContainer {...props} />            
            {ShouldShowMapInBookingCard(props.BookingDetails) && <TaxiTrackingGoogleMap BookingDetails={props.BookingDetails} />}
            <BookingDetails BookingDetails={props.BookingDetails} /> 
            {!ShouldShowMapInBookingCard(props.BookingDetails) && <BookingCardStatusBlock BookingDetails={props.BookingDetails} />}
            <MayBeMultiStopAlert BookingDetails={props.BookingDetails} />
            <BookingCardOperations BookingDetails={props.BookingDetails} />
        </div>          
    );
}

/**
 * Address details
 */
const BookingDetails: React.FC<BookingTrackingProps> = (props) => {
    const shouldShowReadOnlyText = props.BookingDetails.DataOwnership === BookingDataOwnership.ReadOnlyLink;

    return (
        <div className="address-details">
            {shouldShowReadOnlyText && <div className="read-only-text">This is a shared booking.</div>}
            {
                props.BookingDetails.Locations.map((location, index) => {
                    let addressIcon = index === 0 ? GetBrandedUrl(BrandedImage.PickupAddressA) : getContentUrl(ContentURL.images.MyBookings.DropoffIcon);

                    if (index === props.BookingDetails.Locations.length - 1) addressIcon = getContentUrl(ContentURL.images.MyBookings.DestinationIcon);

                    let addressClass = index === props.BookingDetails.Locations.length - 1 ? "pickup-dest-address dropoff-address" : "pickup-dest-address";
                    if (index !== 0 && index !== props.BookingDetails.Locations.length - 1) addressClass += " top-border";

                    return <div className={addressClass} key={index}>
                        <span className="address-icon"><img src={addressIcon} alt="Address"></img></span>
                        {(index !== props.BookingDetails.Locations.length - 1) && <img src={getContentUrl(ContentURL.images.MyBookings.ConnectorLine)} className="connector-line" alt="" ></img>}
                        <div>{location.AddressText}</div>
                    </div>
                })
            }
        </div>
    );
}

/**
 * Show booking status for cancelled or completed bookings.
 */
const BookingCardStatusBlock: React.FC<BookingTrackingProps> = (props) => {
    
    if (props.BookingDetails.TrackingInfo.BookingStatusID == BookingStatus.Cancelled || props.BookingDetails.TrackingInfo.BookingStatusID == BookingStatus.Completed) {
        let statusImage = getContentUrl(ContentURL.images.RedCirleWithX);
        let statusText = "Booking Cancelled";
        let statusTextClass = "status-text";

        if (props.BookingDetails.TrackingInfo.BookingStatusID == BookingStatus.Completed) {
            statusImage = getContentUrl(ContentURL.images.GreenCircleWithCheck);
            statusText = "Trip Completed";

            if (props.BookingDetails.IsDeliveryBooking) {
                statusText = "Delivery Completed";
            }
        }
        else if (props.BookingDetails.TrackingInfo.WasJitPreauthFailure) {
            statusTextClass = "status-text-error";
            if (props.BookingDetails.TrackingInfo.JitPreauthFailureMessage?.startsWith("Insufficient funds")) {
                statusText = "Insufficient funds available";
            }
            else {
                statusText = "Payment method could not be verified";
            }
        }

        return (
            <div className="replace-condition-with-status">
                <img src={statusImage} className="status-image" alt=""></img>
                <div className={statusTextClass}>{statusText}</div>
            </div>
        );
    }

    return null;
}

/**
 * Buttons of operations per booking in list
 */
const BookingCardOperationsCore: React.FC<BookingTrackingProps & RouteComponentProps> = (props) => {

    /** Open brand specific contact us page. */
    function OpenContactUs() {

        if (GetValues().ExternalContactUsUrl) {
            window.open(
                GetValues().ExternalContactUsUrl!, // This is not null inside the if block. TS still needs assertion.
                '_blank' // Open in a new tab
            );
            return;
        }

        props.history.replace(RouteUrls.ContactUs);
    }

    function CanCancelBooking() {
        if (props.BookingDetails.DataOwnership === BookingDataOwnership.ReadOnlyLink) return false;

        // Cannot cancel bookings after picked up by the driver.
        if (props.BookingDetails.TrackingInfo.BookingStatusID < BookingStatus.PickedUp) return true;
        return false;
    }

    // Completed/Cancelled
    function ShouldShowContactUs() {
        if (props.BookingDetails.DataOwnership === BookingDataOwnership.ReadOnlyLink) return false;
        if (props.BookingDetails.TrackingInfo.BookingStatusID == BookingStatus.Completed) return true;
        if (props.BookingDetails.TrackingInfo.BookingStatusID == BookingStatus.NoJob) return true;

        return false;
    }

    function ShouldShowBookAgain() {
        if (props.BookingDetails.DataOwnership === BookingDataOwnership.ReadOnlyLink) return false;

        // this becomes true only when the booking is cancelled by the server. Therefore no need to check whether the booking is cancelled.
        if (props.BookingDetails.TrackingInfo.WasJitPreauthFailure) return true;

        return false;
    }

    // Only active bookings are allowed to share. Currently only available for V1 API.
    function ShouldShowShare() {
        if (props.BookingDetails.DataOwnership === BookingDataOwnership.ReadOnlyLink) return false;
        if (props.BookingDetails.TrackingInfo.BookingStatusID < BookingStatus.Completed) return true;

        return false;
    }

    // Any read only booking can be removed from the list
    function ShouldShowRemove() {
        if (props.BookingDetails.DataOwnership === BookingDataOwnership.ReadOnlyLink) return true;
        if (props.BookingDetails.TrackingInfo.BookingStatusID >= BookingStatus.Completed) return true;

        return false;
    }
    
    // Only shows for non-test mode
    if (props.BookingDetails.DataOwnership === BookingDataOwnership.Test) return null;

    return (
        <div className="expanded-footer">
            {CanCancelBooking() && <button onClick={() => { TryCancelTheBooking(props.BookingDetails) }} className="booking-card-button">Cancel Booking</button>}
            {ShouldShowContactUs() && <button onClick={() => { OpenContactUs() }} className="booking-card-button">Contact Us</button>}
            {ShouldShowShare() && <button onClick={() => { ShareTheBooking(props.BookingDetails) }} className="booking-card-button share-button"><img src={GetBrandedUrl(BrandedImage.ShareBookingIcon)} className="share-icon" alt="Share icon"></img>Share</button>}
            {ShouldShowBookAgain() && <button onClick={() => { PopulateSelectedTripDetails(RecentTripButtonKind.BookAgain, props.BookingDetails, QuickBookSource.BookingCard) }} className="booking-card-button">Reuse trip detals for new request</button>}
            {ShouldShowRemove() && <button onClick={() => { RemoveBookingCard(props.BookingDetails) }} className="booking-card-button">Remove</button>}
        </div>
    );
}

const BookingCardOperations = withRouter(BookingCardOperationsCore);

/**
 * Map will only show when:
 *   1) Feature flag enabled;
 *   2) Accepted by driver;
 *   3) Picked up;
 */
function ShouldShowMapInBookingCard(bookingDetails: BookingInfo): boolean {
    return (bookingDetails.TrackingInfo.BookingStatusID === BookingStatus.Accepted || bookingDetails.TrackingInfo.BookingStatusID === BookingStatus.PickedUp);
}

/** Display an alert (info, really) asking the user to keep the waiting times at stops to minimum. */
const MayBeMultiStopAlert: React.FC<BookingTrackingProps> = (props) => {

    if (props.BookingDetails.Locations.length <= 2) return null;
    if (props.BookingDetails.TrackingInfo.BookingStatusID >= BookingStatus.Completed) return null;

    return <div className="multi-stop-alert">
        <SnackBar DisplayText="Please keep your stops to under 3 min as a courtesy to your driver." CustomIcon={null} Type={SnackbarType.Warning}></SnackBar>
    </div>
}
