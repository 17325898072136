import React from 'react';
import { connect } from 'react-redux';
import './CommonDialogHeader.scss';
import { ApplicationState } from '../../appState';
import { getContentUrl, ContentURL }  from '../Utils/ContentURL';
import { Dispatch } from '../Dispatch';
import { GetBrandedUrl, BrandedImage } from "../Utils/BrandedContentUrls";
import { DialogConfig, DialogConfigLookup } from "./DialogConfig";
import { DialogKind } from "./DialogEntities";
import { useHistory } from 'react-router-dom';

interface DialogHeaderProps { 
    TopmostDialog: DialogKind;
};

/**
 * Common header of dialog
 */
export const CommonDialogHeaderCore: React.FC<DialogHeaderProps> = (props) => {

    // CommonDialogHeader won't render if there is no dialog config
    const config = DialogConfigLookup(props.TopmostDialog);

    if (!config || config.HideCommonDialogHeader) return null;

    return (
        <div className="common-dialog-header-wrapper">
            <DialogHeaderBackButton Config={config} />
            <DialogHeaderTitle Config={config} />
            {config.HelpButtonLink && <a className="header-help" href={config.HelpButtonLink} target="_blank">Help</a>}
        </div>
    )
}

function mapStateToProps(state: ApplicationState): DialogHeaderProps {
    return {
        TopmostDialog: state.dialog.topmostDialog!
    };
}

export const CommonDialogHeader = connect(mapStateToProps)(CommonDialogHeaderCore);

interface DialogConfigProps {
    Config: DialogConfig;
};

/**
 * "<Back" link in the common dialog header.
 */
export const DialogHeaderBackButton: React.FC<DialogConfigProps> = ({ Config }) => {

    const history = useHistory();
    
    const xBtn = Config.RequireBackButton ? (
        <div className="back-click-area" onClick={BackLinkPressed}>
                <img src={getContentUrl(ContentURL.images.arrows.GreyLeftArrow)} alt="Left arrow" />
                <span>Back</span>
        </div> 
    ) : null;

    return (
        <div className="dialog-header-back-panel">{xBtn}</div>
    );

    /** 
     *  User presses "< Back". Normally close the current dialog, unless a custom behaviour is defined.
     */
    function BackLinkPressed() {
        if (Config.CustomBackButtonBehaviour) {
            Config.CustomBackButtonBehaviour(history);
        }
        else {
            Dispatch.Dialog.CloseTopDialog();
        }
    }
}

/**
 * Title in dialog header, which show title or branded logo 
 */
const DialogHeaderTitle: React.FC<DialogConfigProps> = ({ Config }) => {

    const content = (Config.Title && !Config.ShowLogo
                    ? <span>{Config.Title}</span> 
                    : <img src={GetBrandedUrl(BrandedImage.Logo)} width="auto" height="30px" alt="logo" className="logo" />);
    
    return (
        <div className="dialog-header-title">{content}</div>
    );
}

