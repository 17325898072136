import React from 'react';
import { connect } from "react-redux";
import { DialogKind } from '../Dialog/DialogEntities';
import { ContentURL, getContentUrl } from '../Utils/ContentURL';
import '../Verification/ContactVerification.scss';
import { PaymentCard } from "../../Services/PaymentEntities";
import { ApplicationState } from '../../appState';
import { Dispatch } from '../Dispatch';
import { Api } from '../../Services/Api';
import { GetBrandedUrl, BrandedImage } from '../Utils/BrandedContentUrls';
import { PayDriverOption } from '../Payment/PaymentEntities';
import { LogEvent } from '../../utils/LogEvent';
import appstore from '../../appStore';

interface PropsFromStore {
    cardToRemove: PaymentCard;
    cardsList: PaymentCard[];
}

interface stateProps {
    showSpinner: boolean;
}

/** 
 * Confirmation prompt to the user who has indicated to delete a payment card.
 * This component is very similar to Cancel booking, I have used the same css
 */
class RemovePaymentCardConfirmation extends React.Component<PropsFromStore, stateProps> {

    constructor(props: PropsFromStore) {
        super(props);

        this.closeDialog = this.closeDialog.bind(this);
        this.removeCard = this.removeCard.bind(this);

        this.state = {
            showSpinner: false
        }
    }

    closeDialog() {
        Dispatch.Dialog.CloseDialog(DialogKind.RemovePaymentCardConfirmation);
    }

    /**
     * This method will first remove the card, by calling delete card service and if it is successfull, 
     * it will remove it from the state
     */
    async removeCard() {
        LogEvent.OnAgreeingToRemovePaymentCard();
        this.setState({ showSpinner: true });

        const removeCardResponsePayload = await Api.Payment.DeleteCard(this.props.cardToRemove.CardId);

        /** if there is no error, remove it from the state */
        if (removeCardResponsePayload.isSuccess) {

            // remove from cards list
            Dispatch.Payment.RemoveCard(this.props.cardToRemove);

            // remove from booking, if it was selected
            const selectedOption = appstore.getState().booking.PaymentOption;

            if (selectedOption?.Card?.CardId === this.props.cardToRemove.CardId) {
                Dispatch.Booking.PaymentMethod(PayDriverOption);
            }
        }

        this.setState({ showSpinner: false });
        this.closeDialog();
    }

    render() {
        const illustratorClass = this.state.showSpinner ? "popup-illustrator low-opacity" : "popup-illustrator";

        return (
            <div className="contact-details remove-payment-card">
                <h1 className="orange-header">Are you sure?</h1>

                <img src={GetBrandedUrl(BrandedImage.ThinkingGirl)} className={illustratorClass} alt="Image of a girl"></img>

                <p className="popup-description"> Are you sure you want to remove this card?</p>

                <div className="popup-btn-group">
                    <button type="button" className="cancel-btn half-btn" onClick={this.removeCard}>Yes Remove</button>
                    <button type="button" className="active-btn half-btn" onClick={this.closeDialog}>No</button>
                </div>

                {this.state.showSpinner ? <div className="cancel-spinner"><img alt="Cancelling" src={getContentUrl(ContentURL.images.Loading)} width="100" /></div> : ""}
            </div>
        );
    }
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        cardToRemove: state.payment.CardToRemove!,
        cardsList: state.payment.AllCards
    };
}

export default connect(mapStateToProps)(RemovePaymentCardConfirmation);