import React, { ChangeEvent } from "react";
import { connect } from "react-redux";
import moment, { Moment } from "moment";
import { ConsiderFareUpdate } from "../Condition/FareLoader";
import { ApplicationState } from "../../appState";
import DatePicker from "react-datepicker";
import { getContentUrl, ContentURL } from "../Utils/ContentURL";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "rc-time-picker";
import 'rc-time-picker/assets/index.css';
import "./BookingDateTime.scss";
import { Dispatch } from "../Dispatch";
import { TryGetMobileOsName } from "../../utils/DetectDevice";
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { MobileOSKind } from "../../Config/Entities/DeviceKind";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Radio, RadioGroup, FormControlLabel, InputAdornment, IconButton} from '@mui/material'
import { DateTime } from "luxon";
import appstore from "../../appStore";
import { CheckFutureBookingTime, CheckFutureBookingTimeResult, GetBookInAdvanceDays } from "./CheckBookingTime";
import { PickupServiceCheckState, ServiceCheckStatus } from "./BookingEntities";
import ScheduleIcon from '@mui/icons-material/Schedule';
import { UILayoutMode } from "../UILogicControl/UILogicControlEntities";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

interface PropsFromStore {

    /** How many days in advance from today the user can book */
    BookInAdvanceDays: number;

    /** Information about the pickup location, including the time zone. Possibly in an intermediate (loading) state. */
    PickupServiceCheck: PickupServiceCheckState;
}

/** The two possible input states for this UI, represented by a radio group on the UI */
enum TimeMode {

    /** Now means no date/time UI is visible. */
    Now = "now",

    /** Later means the date/time UI is displayed and requires input. */
    Later = "later",
}

interface BookingDateTimeState {

    /** The "now" or "later" option from the radio group on the UI */
    bookingTimeMode: TimeMode;

    /** 
     * The future date and time value, when the time mode is set to "later".
     * For convenience, this will be typed as non-null, even when the time mode is set to "now".
     */
    selectedDate: DateTime;

    /** 
     *  Hover state of the Date picker for desktop devices.
     *  This supports a silly bounce animation on the dropdown arrow.
     */
    isDateArrowOnHover: boolean;

    /**
     *  Hover state of the Time picker for desktop devices.
     *  This supports a silly bounce animation on the dropdown arrow.
     */
    isTimeArrowOnHover: boolean;

    /** 
     * Whether the data picker has input focus.
     * It adds an outline around the date text field while the calender UI is open.
     */
    isDatePickerInputFocused: boolean;

    /** 
     * Whether the data picker Modal window is open / closed.
     * It Opens the Modal when true and closed when false.
     */
     isAndroidDatePickerOpen: boolean;
}

/** There are some properties available in the TimePicker that are not declared in the type definition of the package. eg: getPopupContainer. Hence, casting it explicitly to any type to avoid typescript errors.*/
let CustomTimePicker = TimePicker as any;

/**
 * The UI to select the date and time of a new booking.
 * First you pick from "now" or "later" options.
 * When the option is "later", you get a calendar and time picker for a specific time.
 */
class BookingDateTime extends React.Component<PropsFromStore, BookingDateTimeState> {        

    private calendar = React.createRef<DatePicker>();

    constructor(props: PropsFromStore) {
        super(props);

        this.state = {
            bookingTimeMode: TimeMode.Now,

            // this is a placeholder / unused value. It will always be overwritten by either TryRestorePreviousBookingTime() or onTimeModeChange().
            selectedDate: DateTime.now(), 
            isDateArrowOnHover: false,
            isTimeArrowOnHover: false, 
            isDatePickerInputFocused: false,
            isAndroidDatePickerOpen: false,
        }

        this.onDatePickerChanged = this.onDatePickerChanged.bind(this);
        this.onTimePickerChanged = this.onTimePickerChanged.bind(this);
        this.onDatePickerInputFocusChange = this.onDatePickerInputFocusChange.bind(this);
    }
    
    /**
     * The intent is to update the SelectedDate STATE when the PickupServiceCheck PROP changes.
     * In particular, when the pickup location changes to a new time zone, update the selected date to match.
     */
    static getDerivedStateFromProps(nextProps: Readonly<PropsFromStore>, prevState: BookingDateTimeState): Partial<BookingDateTimeState> | null {

        // warning: if we change the state value, we'd probably need to dispatch out as well
        // hmm, not sure about this.
        if ((prevState.bookingTimeMode === TimeMode.Later) && (nextProps.PickupServiceCheck.status === ServiceCheckStatus.KnownGood) && (nextProps.PickupServiceCheck.TimeZoneId !== prevState.selectedDate.zoneName)) {
            return {
                selectedDate: prevState.selectedDate.setZone(nextProps.PickupServiceCheck.TimeZoneId),
            };
        }

        // no changes needed
        return null;
    }

    componentDidMount() {
        this.TryRestorePreviousBookingTime();
    } 

    /**
     * Reuse any future date that had previously been set. 
     * Mainly for mobile UI where this component gets unmounted and remounted a lot.
     */
    TryRestorePreviousBookingTime() {
        const lastUsedBookingTime = appstore.getState().booking.BookingTimeV2;

        if ((lastUsedBookingTime.IsImmediate === false) && (lastUsedBookingTime.RequestedDate > DateTime.now())) {

            this.setState({
                bookingTimeMode: TimeMode.Later,
                selectedDate: lastUsedBookingTime.RequestedDate,
            });
        }
    }

    /** Get the current time in the time zone in context (pickup location). */
    GetBookingZoneNow(): DateTime {

        const timeZoneId = this.GetBookingTimeZone();
        return DateTime.now().setZone(timeZoneId);
    }

    /** The selected date, but represented in the time zone of the booking */
    GetBookingZoneSelectedTime(): DateTime {

        const timeZoneId = this.GetBookingTimeZone();
        return this.state.selectedDate.setZone(timeZoneId);
    }

    /** A nonsense time using the local time in the booking's time zone, but expressed in the current user's time zone, which is all Moment understands. */
    GetFakeMomentSelectedTime(): DateTime {

        const actualTime = this.GetBookingZoneSelectedTime();
        const fakeTime = actualTime.setZone(DateTime.now().zoneName, { keepLocalTime: true });
        return fakeTime;
    }

    /** Gets the time zone ID of the booking location. Fall back to the user's current time zone. */
    GetBookingTimeZone(): string {

        const check = this.props.PickupServiceCheck;

        if (check.status != ServiceCheckStatus.KnownGood) {
            return DateTime.now().zoneName;
        }

        return check.TimeZoneId;
    }

    // #region Event Handlers

    /** Mouse hover event handler for the Time Picker in desktop mode. */
    onTimePickerButtonHover = (isHoverNowTrue: boolean) => {
        this.setState({ isTimeArrowOnHover: isHoverNowTrue });
    }

    /** Mouse hover event handler for the Date Picker in desktop mode. */
    onDatePickerButtonHover = (isHoverNowTrue: boolean) => {
        this.setState({ isDateArrowOnHover: isHoverNowTrue });
    }

    /**
     * Update the [isDatePickerInputFocused] state from the focus / blur UI Events.
     */
    onDatePickerInputFocusChange(isNowFocused: boolean): void {
        this.setState({ isDatePickerInputFocused: isNowFocused });
    }

    /** Change event handler for the "now" / "later" radio group */
    onTimeModeChange(e: React.ChangeEvent<HTMLInputElement>) {

        // this cast is safe based on the hard coded values set for the radio group labels in render()
        const newTimeMode = e.target.value as TimeMode;
        this.setState({ bookingTimeMode: newTimeMode });

        if (newTimeMode === TimeMode.Now) {
            Dispatch.Booking.TimeForNow();
            ConsiderFareUpdate();
        }
        else if (newTimeMode === TimeMode.Later) {

            // fix a previously stored date that is now too old
            const minValidDate = DateTime.now().startOf('minute').plus({ minutes: 5 });

            if (this.state.selectedDate <= minValidDate) {

                this.setState({ selectedDate: minValidDate });
                this.AnnounceNewDateValue(minValidDate);
            }
            else {
                this.AnnounceNewDateValue(this.state.selectedDate);
            }
        }
    }

    /**
     * Change handler for the iOS-specific combined (date + time) UI element.
     * TODO: probably check .isValid and abort on error
     */
    onIosDateTimeChange = (e: ChangeEvent<HTMLInputElement>) => {

        const dateTimeText: string = e.target.value;
        const dateTimeValue = DateTime.fromISO(dateTimeText);

        // <input type="datetime-local"> gives the time in a non-specified timezone
        // we need to set the actual timezone to match the location
        const bookingTimeZone = this.GetBookingTimeZone();
        const localDateTime = dateTimeValue.setZone(bookingTimeZone, { keepLocalTime: true });

        // local state
        this.setState({ selectedDate: localDateTime });
    }

    /**
     * Change handler for the Android-specific combined (date + time) UI element.
     * TODO: need to undersand what null means. How does it happen on the UI? 
     * Is a better fallback behaviour to reset the time to now?
     */
    onAndroidDateTimeChange = (date: DateTime | null) => {

        // Date must be specified
        if (!date) return;

        // local state
        this.setState({ selectedDate: date });
    }

    /**
     * Android-specific combined DateTime Picker UI element Modal window Toggle.
     */
     onAndroidDateTimePickerToggle = () => {
        this.setState({ isAndroidDatePickerOpen : !this.state.isAndroidDatePickerOpen})
    }

    /** 
     * Change event handler for the Date Picker on desktop devices.
     * It should only update the date properties (year, month, day).
     * TODO: not sure how null can come through, or what we should do in this case.
     * Previous docs suggest clicking a "clear" icon in the input field will do this.
     */
    onDatePickerChanged(date: Date | null) {

        if (date == null) return;

        const newDate = DateTime.fromJSDate(date);

        const newDateTime = this.state.selectedDate.set({
            year: newDate.year,
            month: newDate.month,
            day: newDate.day,
        });

        // local state
        this.setState({ selectedDate: newDateTime });

        this.AnnounceNewDateValue(newDateTime);
    }

    /** 
     * Change event handler for the time picker on desktop devices.
     * It should only update the time properties (hour and minute).
     * WARNING: since moment can't handle other time zones, we have fooled it with a faked time in the user's time zone. We need to undo the fudge here to work out the real time.
     */
    onTimePickerChanged(time: Moment) {

        // 1) get previous value in the nonsense Moment format
        const previousValue = this.GetFakeMomentSelectedTime();

        // 2) apply changes
        const newValue = previousValue.set({
            hour: time.get('hour'),
            minute: time.get('minute'),
        });

        // 3) convert back to the actual timezone
        const realTimeZoneId = this.GetBookingTimeZone();
        const newDateTime = newValue.setZone(realTimeZoneId, { keepLocalTime: true });

        // local state
        this.setState({ selectedDate: newDateTime });
    }

    // #endregion

    /**
     * Report the changed date to Redux state.
     * TODO: check date.IsValid, then date.InvalidReason if it fails
     */
    AnnounceNewDateValue(date: DateTime): void {
        // global state
        Dispatch.Booking.TimeForFuture(date);
        ConsiderFareUpdate();
    }

    /** 
     * Validate the selected date. 
     * This value is computed dynamically whenever needed because it is a function of both props and state.
     */
    ValidateTime(): CheckFutureBookingTimeResult {

        // now is always valid
        if (this.state.bookingTimeMode === TimeMode.Now) {
            return {
                IsValid: true,
                ErrorMessage: null,
            };
        }

        return CheckFutureBookingTime(this.state.selectedDate, this.props.BookInAdvanceDays);
    }

    // #region Render

    render() {
        
        return (
            <div className="date-time-component">                                
                <RadioGroup value={this.state.bookingTimeMode} onChange={e => this.onTimeModeChange(e)}>
                    <FormControlLabel value={TimeMode.Now} control={<Radio color="secondary" tabIndex={0} />} label="Book for now" />
                    <FormControlLabel value={TimeMode.Later} control={<Radio color="secondary" tabIndex={0} />} label="Book for later" />
                </RadioGroup>
                
                {this.RenderFutureTimeUI()}
            </div>
        );
    }

    /** Renders the future date selection UI block when the "later" option is selected */
    RenderFutureTimeUI(): JSX.Element | null {

        if (this.state.bookingTimeMode === TimeMode.Now) return null;

        const isDesktopDevice = appstore.getState().uiLogicControl.LayoutMode === UILayoutMode.Desktop;

        const dateAndTimeWrapperClass = isDesktopDevice && this.state.bookingTimeMode === TimeMode.Later ? "future-dt add-margin-bottom" : "future-dt";

        return (
            <div className={dateAndTimeWrapperClass}>
                {this.RenderTimeZoneNotice()}
                {this.RenderDateTimePicker()}
                {this.RenderErrorMessage()}
            </div>
        );
    }

    /** A small label reminding the user when the booking location is in another time zone. */
    RenderTimeZoneNotice(): JSX.Element | null {

        if (this.IsOutsideMyTimeZone()) {

            const epochMillis = this.state.selectedDate.toMillis();
            const timeZoneName = this.state.selectedDate.zone.offsetName(epochMillis, {
                format: "short"
            });

            return (
                <div className="calendar-info">
                    <ScheduleIcon fontSize="small" />
                    <div className="info-text">You're booking in another time zone ({timeZoneName})</div>
                </div>
            );
        }

        // normal case
        return null;
    }

    /** 
     *  Returns true if the booking location is in a different time zone compared to the user.
     *  This will cause an extra message to display UI.
     *  Really it's only a problem if the timezone offset is different for the selected date.
     */
    IsOutsideMyTimeZone(): boolean {

        // indeterminate
        const check = this.props.PickupServiceCheck;
        if (check.status != ServiceCheckStatus.KnownGood) return false;

        const bookingOffset = this.state.selectedDate.offset;
        const myLocalOffset = this.state.selectedDate.toLocal().offset;

        return bookingOffset != myLocalOffset;
    }

    /** Renders the error message text block for invalid dates */
    RenderErrorMessage(): JSX.Element | null {

        // no error to display
        const validation = this.ValidateTime();
        if (validation.IsValid) return null;

        // some extra spacing for desktop devices
        const mobileOs = TryGetMobileOsName();
        const isSupportedMobileDevice = mobileOs === MobileOSKind.IOS || mobileOs === MobileOSKind.Android;

        const errorMessageCssClass = isSupportedMobileDevice ? "booking-form-error-message" : "booking-form-error-message error-message-position set-margin-top";

        return <p className={errorMessageCssClass}>{validation.ErrorMessage}</p>
    }

    /** Render the datetimepicker depending on the device OS (e.g. iOS, Android, etc.) */
    RenderDateTimePicker = () => {
        
        // Set the max booking date
        const maxDate = this.GetBookingZoneNow().plus({ days: this.props.BookInAdvanceDays }).endOf('day');
        const operatingSysName = TryGetMobileOsName();
        const validation = this.ValidateTime();

        // Load the datetime picker depending on the device's operating system
        switch (operatingSysName) {

            case MobileOSKind.IOS:
                return (
                    <TextField
                        className="native-datepicker"
                        fullWidth={true}
                        variant="outlined"
                        label="Pickup date"
                        type="datetime-local"
                        onChange={this.onIosDateTimeChange}
                        onBlur={() => {this.AnnounceNewDateValue(this.state.selectedDate);}}
                        error={!validation.IsValid}
                        value={this.state.selectedDate!.toFormat("yyyy-MM-dd'T'HH:mm")}
                    />
                );
            
            case MobileOSKind.Android:
                return (
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DateTimePicker
                            maxDate={maxDate}
                            minDateTime={DateTime.now()}
                            open={this.state.isAndroidDatePickerOpen}
                            onOpen={this.onAndroidDateTimePickerToggle}
                            onClose={() => {
                                this.onAndroidDateTimePickerToggle();
                                this.AnnounceNewDateValue(this.state.selectedDate);
                            }}
                            disablePast={true}
                            label="Pickup date"
                            inputFormat="yyyy-MM-dd HH:mm"
                            renderInput={(props: TextFieldProps) => <TextField
                                {...props}
                                sx={{width : 1}}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton edge="end">
                                            <CalendarTodayIcon onClick={this.onAndroidDateTimePickerToggle} />
                                          </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />}
                            value={this.state.selectedDate}
                            onChange={this.onAndroidDateTimeChange}
                        />
                  </LocalizationProvider>
                );
        
            default:
                return (
                    <div className="future-dt-fields">
                        <div className="date-time-group date-group">
                            <label className="input-label">Pickup date</label>
                            {this.RenderDatePicker()}
                        </div>
                        <div className="date-time-group time-group">
                            <label className="input-label">Pickup time</label>
                            {this.RenderTimePicker()}
                        </div>
                    </div>
                );
        }
    }

    /** Render the Date picker element for desktop devices, or other devices for which we don't have a device-specific UI. */
    RenderDatePicker(): JSX.Element {

        // range
        const minDate = DateTime.now().toJSDate();
        const maxDate = DateTime.now().plus({ days: this.props.BookInAdvanceDays }).toJSDate();

        let dateClass = "datepicker-visible-container date-selector";
        const validation = this.ValidateTime();

        if (!validation.IsValid) {
            dateClass = dateClass + " datetime-invalid-input";
        }
        else if (this.state.isDatePickerInputFocused) {
            dateClass = dateClass + " date-field";
        }

        const dateArrowBtnImg = this.state.isDateArrowOnHover ? getContentUrl(ContentURL.images.arrows.arrowRightBlackSolid) : getContentUrl(ContentURL.images.arrows.arrowRightGreySolid);

        const currentValue = this.state.selectedDate?.toJSDate();

        return (
            <label onClick={e => this.calendar.current!.isCalendarOpen() && e.preventDefault()}>
                <DatePicker
                    ref={this.calendar}
                    selected={currentValue}
                    dateFormat="dd/MM/yyyy"
                    onChange={this.onDatePickerChanged}
                    onSelect={this.onDatePickerChanged}
                    minDate={minDate}
                    maxDate={maxDate}
                    className="disable-default-datepicker-style"
                    onFocus={() => this.onDatePickerInputFocusChange(true)}
                    onBlur={() => this.onDatePickerInputFocusChange(false)}
                    onCalendarClose={() => this.onDatePickerInputFocusChange(false)}
                />

                <div className={dateClass}>
                    <div className="dropdown-section"
                        onMouseEnter={() => { this.onDatePickerButtonHover(true) }}
                        onMouseLeave={() => { this.onDatePickerButtonHover(false) }}
                    >
                        <img src={dateArrowBtnImg} alt="" width="15" />
                    </div>
                </div>
            </label>
        );
    }

    /** Render the Time picker element for desktop devices. */
    RenderTimePicker(): JSX.Element {

        const validation = this.ValidateTime();

        const timeClass = !validation.IsValid ? "datetime-invalid-input input-field time-field" : "time-selector input-field time-field";
        const timeArrowBtnImg = this.state.isTimeArrowOnHover ? getContentUrl(ContentURL.images.arrows.arrowRightBlackSolid) : getContentUrl(ContentURL.images.arrows.arrowRightGreySolid);

        // Dropdown section for the time picker
        const inputIcon = (<div className="dropdown-section"
            onMouseEnter={() => { this.onTimePickerButtonHover(true) }}
            onMouseLeave={() => { this.onTimePickerButtonHover(false) }}
        ><img src={timeArrowBtnImg} alt="" width="15" /></div>);

        // we need to give the time picker a Moment time
        const fakeTime = this.GetFakeMomentSelectedTime();
        const selectionMoment = moment(fakeTime.toISO()); 

        return (
            <CustomTimePicker
                defaultValue={selectionMoment}
                value={selectionMoment} // not sure about this...
                showSecond={false}
                use12Hours={true}
                inputIcon={inputIcon}
                className={timeClass}
                onChange={this.onTimePickerChanged}
                onClose={() => {this.AnnounceNewDateValue(this.state.selectedDate);}}
                format={'h:mm A'}
                focusOnOpen
                getPopupContainer={(trigger: { parentNode: any; }) => trigger.parentNode}
                addon={(panel: { close: () => void; }) => {
                    return (
                        <div className="row">
                            <button className="done-btn" onClick={() => panel.close()}>
                                Done
                            </button>
                        </div>
                    )
                }}
            />
        );
    }

    // #endregion
}

function mapStateToProps(state: ApplicationState): PropsFromStore {
    return {
        BookInAdvanceDays: GetBookInAdvanceDays(state.authentication.UserProfile),
        PickupServiceCheck: state.booking.PickupServiceCheck,
    };
}

export default connect(mapStateToProps)(BookingDateTime);