import React from 'react';
import { BookingTrackingProps } from './ExpandedSection';

export interface ProofOfDeliveryImageCarouselProps {
    ShowCarouselAt: (imageIndex: number) => void;
}

/**
 * Displays the proof of delivery images on the booking card for completed delivery bookings
 */
export const ProofOfDeliveryImageContainer: React.FC<BookingTrackingProps & ProofOfDeliveryImageCarouselProps> = (props) => {

    const podUrls = props.BookingDetails.TrackingInfo.ProofOfDeliveryUrls;
    if (!podUrls) return null;
    if (podUrls.length === 0) return null;

    return (
        <div className="proof-of-delivery-container">
            {
                podUrls.map((image, index) => 
                    <img key={index} src={image} onClick={() => props.ShowCarouselAt(index)} alt="A photo of a parcel" />
                )
            }
        </div>
    );
}